import React from "react";
import { Link, useNavigate } from "react-router-dom";

const HeroSection = () => {

  return (
    <div>
      {/* <!--breatcome-area Start--> */}
      <div className="breatcome-area align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breatcome-content">
                <div className="breatcome-title">
                  <h1>Pricing</h1>
                </div>
                <div className="breatcome-text">
                  <ul>
                    <li>
                      <Link to="/">
                        Home <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <span>Pricing</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- breatcome-area end--> */}
      {/* <!--pricing-area-start--> */}
      <div className="pricing-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title t-center">
                <div className="section-sub-title">
                  <h5>
                    Perfect Number <span></span>
                  </h5>
                </div>
                <div className="section-main-title">
                  <h1>Pick Your Perfect Telephone Number</h1>
                  <p className="mt-3">
                    One month rolling contract, first class service and over 10
                    free features with every number.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing">
                <div className="pricing-content active-star">
                  <div className="pricing-icon-thumb">
                    <img src="assets/images/price2.png" alt="icon" />
                  </div>
                  <div className="pricing-title">
                    <h3 className="mb-2">Local Numbers</h3>
                    <p>01/02 Landlines</p>
                  </div>
                  <div className="pricing-item mt-5">
                    <span className="price-dolar-sign">$</span>
                    <span className="price-dolar">£5.78 </span>
                    <span className="price-dolar-sign">/ month</span>
                  </div>
                  <div className="pricing-body">
                    <ul>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>The
                        local numbers
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>
                        Establish a presence in any UK town or city
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>Show
                        you are a local, trustworthy business
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>
                        Perfect for shops, trades, offices & more
                      </li>
                    </ul>
                    <div className="pricing-button mt-4">
                      <div className="zomia-button">
                        <Link to="/plan0102"
                          role="button"
                        >
                          Choose Your Number
                          <i className="flaticon flaticon-right-arrow"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing">
                <div className="pricing-content active-star">
                  <div className="pricing-icon-thumb">
                    <img src="assets/images/price1.png" alt="icon" />
                  </div>
                  <div className="pricing-title">
                    <h3 className="mb-2">0800 Numbers</h3>
                    <p>Freephone</p>
                  </div>
                  <div className="pricing-item mt-5">
                    <span className="price-dolar-sign">$</span>
                    <span className="price-dolar">£5.78 </span>
                    <span className="price-dolar-sign">/ month</span>
                  </div>
                  <div className="pricing-body">
                    <ul>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>The
                        freephone number
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>Free
                        to call from landlines and mobiles
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>Great
                        for increasing enquiries, giving your customers a number
                        that’s free to call
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>
                        Memorable numbers for advertising
                      </li>
                    </ul>
                    <div className="pricing-button">
                      <div className="zomia-button">
                        <Link to="/plan0800"
                          role="button"
                        >
                          Choose Your Number
                          <i className="flaticon flaticon-right-arrow"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single-pricing ">
                <div className="pricing-content active-star">
                  <div className="pricing-icon-thumb">
                    <img src="assets/images/price3.png" alt="icon" />
                  </div>
                  <div className="pricing-title">
                    <h3 className="mb-2">03 Numbers</h3>
                    <p>Nationwide Presence</p>
                  </div>
                  <div className="pricing-item mt-5">
                    <span className="price-dolar-sign">$</span>
                    <span className="price-dolar">£5.78 </span>
                    <span className="price-dolar-sign">/ month</span>
                  </div>
                  <div className="pricing-body">
                    <ul>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>Give
                        your business a nationwide presence with an 03 number
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>Same
                        cost to call as a regular UK landline
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>
                        Perfect if you want to show you are a large, established
                        business
                      </li>
                      <li>
                        <i aria-hidden="true" className="fas fa-check"></i>Great
                        for online businesses
                      </li>
                    </ul>
                    <div className="pricing-button mt-4">
                      <div className="zomia-button">
                        <Link to="/plan03"
                          role="button"
                        >
                          Choose Your Number
                          <i className="flaticon flaticon-right-arrow"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--pricing-area-end--> */}
    </div>
  );
};

export default HeroSection;
