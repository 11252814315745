import React from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";

const MissedCallAlert = () => {
  return (
    <div>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-10 mx-auto">
              {/* Missed Call Alerts Card */}
              <div className="card p-4 mb-4 shadow-lg">
                <h3 className="mb-3 text-primary">Missed Call Alerts</h3>
                <p>
                  We can notify you if you miss a call. Please choose the type
                  of call you would like to be notified about and then specify
                  the email address(es) which you would like to be notified.
                </p>
                <p className="text-muted">
                  You will also need to enable this feature on each telephone
                  number on which you would like to use it, via the options
                  section under Number Administration.
                </p>
              </div>

              {/* Types of Missed Calls Card */}
              <div className="card p-4 mb-4 shadow-lg">
                <h4 className="mb-3 text-primary">Types of Missed Calls</h4>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="callerGaveUp"
                  />
                  <label className="form-check-label mx-2 mt-1" htmlFor="callerGaveUp">
                    Caller Gaveup
                  </label>
                </div>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="engagedBusy"
                  />
                  <label className="form-check-label mx-2 mt-1" htmlFor="engagedBusy">
                    Engaged/busy
                  </label>
                </div>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="destinationProblem"
                  />
                  <label className="form-check-label mx-2 mt-1" htmlFor="destinationProblem">
                    Problem with destination
                  </label>
                </div>
              </div>

              {/* Method of Notification Card */}
              <div className="card p-4 mb-4 shadow-lg">
                <h4 className="mb-3 text-primary">Method of Notification</h4>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="pushNotification"
                  />
                  <label className="form-check-label mx-2 mt-1" htmlFor="pushNotification">
                    Push Notifications
                  </label>
                </div>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="emailNotification"
                  />
                  <label className="form-check-label mx-2 mt-1" htmlFor="emailNotification">
                    Email
                  </label>
                </div>
                <div className="form-check d-flex">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="noNotification"
                  />
                  <label className="form-check-label mx-2 mt-1" htmlFor="noNotification">
                    No Notification for withheld
                  </label>
                </div>

                {/* Save Changes Button */}
                <div className="d-flex justify-content-end mt-4">
                  <button className="btn btn-primary px-4">Save Changes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default MissedCallAlert;
