import React from "react";
import Numbers0333 from "../../component/ControlPanel/Numbers0333";

const Numbers0333Page = () => {
  return (
    <div>
      <Numbers0333 />
    </div>
  );
};

export default Numbers0333Page;
