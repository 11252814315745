import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";

const HuntGroup = () => {
  const [huntGroups, setHuntGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [newHuntGroupName, setNewHuntGroupName] = useState("");
  const [isDeleteEnabled, setIsDeleteEnabled] = useState(false);
  const [voicemailOptions, setVoicemailOptions] = useState([]);

  // Fetch hunt group data (for now, we will simulate a dummy API call)
  useEffect(() => {
    const fetchHuntGroups = async () => {
      try {
        const response = await axios.get("/dummy-hunt-groups"); // replace with actual API
        setHuntGroups(response.data.huntgroups.hunts);
      } catch (error) {
        setHuntGroups([{ ntsid: "dummy1", description: "Dummy Hunt Group 1" }]);
      }
    };

    const fetchVoicemailOptions = async () => {
      try {
        const response = await axios.get("/dummy-voicemail-api"); // replace with actual API
        setVoicemailOptions(response.data.voicemails);
      } catch (error) {
        setVoicemailOptions([{ id: "none", name: "None" }]);
      }
    };

    fetchHuntGroups();
    fetchVoicemailOptions();
  }, []);

  const handleGroupSelect = (e) => {
    setSelectedGroup(e.target.value);
    setIsDeleteEnabled(false);
    setIsEditing(true);
  };

  const handleCreateNew = () => {
    setIsCreating(true);
    setIsEditing(false);
    setSelectedGroup("");
  };

  const handleCreate = () => {
    const userId = localStorage.getItem("user_id");
    const payload = {
      description: newHuntGroupName,
      user_id: userId,
    };

    axios
      .post("/api/create-hunt-group", payload)
      .then((response) => {
        alert("Hunt group created successfully!");
        setIsCreating(false);
        setNewHuntGroupName("");
      })
      .catch((error) => {
        console.error("Error creating hunt group:", error);
      });
  };

  return (
    <div>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-10 mx-auto">
              {/* Hunt Group Administration Card */}
              <div className="card p-4 mb-4">
                <h3>Select an existing Hunt Group or create new</h3>
                <p>
                  To view or make changes to an existing hunt-group, select the
                  hunt-group from the dropdown and then select View/Edit.
                </p>
                <p>
                  To delete an existing hunt group that is not in use by any
                  number on your account, select the hunt-group from the
                  dropdown and then select Delete.
                </p>

                <label className="form-label">Select Hunt Group:</label>
                <select
                  className="form-control border border-primary"
                  onChange={handleGroupSelect}
                  value={selectedGroup}
                >
                  <option value="" disabled>
                    Select Hunt Group
                  </option>
                  {huntGroups.map((group) => (
                    <option key={group.ntsid} value={group.ntsid}>
                      {group.description}
                    </option>
                  ))}
                </select>

                <div className="mt-3">
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => setIsEditing(true)}
                    disabled={!selectedGroup}
                  >
                    View/Edit
                  </button>
                  <button
                    className="btn btn-danger me-2"
                    onClick={() => setIsDeleteEnabled(true)}
                    disabled={!selectedGroup || !isEditing}
                  >
                    Delete
                  </button>
                  <button className="btn btn-success" onClick={handleCreateNew}>
                    Create New
                  </button>
                </div>
              </div>

              {/* Create New Hunt Group Form */}
              {isCreating && (
                <div className="card p-4 mb-4">
                  <h3>Create A New Huntgroup</h3>
                  <label className="form-label">Description</label>
                  <input
                    className="form-control border border-primary"
                    type="text"
                    placeholder="Name for this hunt group"
                    value={newHuntGroupName}
                    onChange={(e) => setNewHuntGroupName(e.target.value)}
                  />
                  <button
                    className="btn btn-primary mt-3"
                    onClick={handleCreate}
                  >
                    Create
                  </button>
                </div>
              )}

              {/* Edit/View Hunt Group Card */}
              {isEditing && (
                <div className="card p-4">
                  <h3>Hunt Group {selectedGroup}</h3>

                  <label className="form-label">Name</label>
                  <input
                    className="form-control mb-3 border border-primary"
                    type="text"
                    value={selectedGroup}
                    readOnly
                  />

                  <label className="form-label">Announcement</label>
                  <select className="form-control mb-3 border border-primary">
                    <option value="none">None</option>
                    <option value="default">
                      Default Call Whisper (Business Call)
                    </option>
                    <option value={selectedGroup}>{selectedGroup}</option>
                  </select>

                  <label className="form-label">Voicemail</label>
                  <select className="form-control mb-3 border border-primary">
                    {voicemailOptions.map((vm) => (
                      <option key={vm.id} value={vm.id}>
                        {vm.name}
                      </option>
                    ))}
                  </select>

                  <label className="form-label">Hunting Type</label>
                  <select className="form-control mb-3 border border-primary">
                    <option value="inorder">Hunt-in-order</option>
                    <option value="random">Random Hunt</option>
                    <option value="last">Last Found</option>
                    <option value="mostidle">Most Idle</option>
                    <option value="rotary">Rotary</option>
                    <option value="simultaneous">Simultaneous Ring All</option>
                  </select>

                  <h5 className="mt-4">Existing Configuration</h5>
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Enable on which days?</th>
                          <th>Start Time</th>
                          <th>End Time</th>
                          <th>VM</th>
                          <th>Divert my calls to</th>
                          <th>Comment</th>
                          <th>Timeout</th>
                          <th>Active</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {[
                              "Mon",
                              "Tue",
                              "Wed",
                              "Thu",
                              "Fri",
                              "Sat",
                              "Sun",
                            ].map((day, index) => (
                              <div key={index} className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value={day}
                                />
                                <label className="form-check-label">
                                  {day}
                                </label>
                              </div>
                            ))}
                          </td>
                          <td>
                            <input className="form-control" type="time" />
                          </td>
                          <td>
                            <input className="form-control" type="time" />
                          </td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="Destination"
                              type="text"
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="Description or comment"
                              type="text"
                            />
                          </td>
                          <td>
                            <input
                              className="form-control"
                              placeholder="Timeout"
                              type="number"
                            />
                          </td>
                          <td>
                            <input
                              className="form-check-input"
                              type="checkbox"
                            />
                          </td>
                          <td>
                            <button className="btn btn-danger">
                              Delete Row
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HuntGroup;
