import React from "react";
import AdminSignup from "../../component/admin/AdminSignup";

const AdminSignupPage = () => {
  return (
    <div>
      <AdminSignup />
    </div>
  );
};

export default AdminSignupPage;
