import React, { useState } from "react";

const PlanCard = ({
  plan,
  index,
  tariffPrice,
  fetchPartialNumbers,
  TariffHandler,
  setButtonDisabled,
  ButtonDisabled,
  setTailor,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="mb-4">
      <div
        className={`card shadow-sm h-100 ${
          index === 2 ? "highlighted-card" : ""
        }`}
        style={{ minHeight: '300px' }}
      >
        <div className="card-body d-flex flex-column">
          <h3 className="card-title text-center mb-3">{plan.name}</h3>
          <p className="card-text display-4 text-center text-primary mb-2">
            {plan.price}
          </p>
          <b className="text-muted text-center d-block mb-4">/Month + VAT</b>

          <ul
            className={`list-group list-group-flush ${
              isExpanded ? "" : "overflow-hidden"
            }`}
            style={{ maxHeight: isExpanded ? "none" : "150px", transition: "max-height 0.3s ease-in-out" }}
          >
            {plan.features.map((feature, idx) => (
              <li key={idx} className="list-group-item d-flex align-items-center">
                <span className="badge bg-secondary me-2"></span>
                <span>{feature}</span>
              </li>
            ))}
            {plan.premiumFeatures && (
              <>
                <p className="text-center fw-bold text-danger mt-3">
                  PLUS These Premium Features
                </p>
                {plan.premiumFeatures.map((feature, idx) => (
                  <li key={idx} className="list-group-item d-flex align-items-center">
                    <span className="badge bg-danger me-2"></span>
                    <span>{feature}</span>
                  </li>
                ))}
              </>
            )}
          </ul>

          {plan.features.length > 5 && (
            <button
              onClick={handleToggleExpand}
              className="btn btn-link mt-auto"
            >
              {isExpanded ? "Show Less" : "Show More"}
            </button>
          )}

          <a href="#tailor" className="mt-3">
            <button
              className={`btn btn-lg btn-block mt-4 ${
                tariffPrice === plan.value ? "btn-warning" : "btn-primary"
              }`}
              onClick={(e) => {
                fetchPartialNumbers();
                TariffHandler(e, plan.price, plan.value, index);

                if (index === 2) {
                  setButtonDisabled(!ButtonDisabled);
                  setTailor("Switchboard");
                } else {
                  setButtonDisabled(false);
                }
              }}
            >
              Build My Number
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
