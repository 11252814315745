import React from "react";
import MissedCallAlert from "../../component/ControlPanel/MissedCallAlert";

const MissedCallAlertPage = () => {
  return (
    <div>
      <MissedCallAlert />
    </div>
  );
};

export default MissedCallAlertPage;
