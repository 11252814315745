import React from "react";
import AlreadyPurchased from "../../component/ControlPanel/AlreadyPurchased";

const AlreadyPurchasedPage = () => {
  return (
    <div>
      <AlreadyPurchased />
    </div>
  );
};

export default AlreadyPurchasedPage;
