import React from "react";
import Plan0102 from "../component/Plan0102";

const Plan0102Page = () => {
  return (
    <div>
      <Plan0102 />
    </div>
  );
};

export default Plan0102Page;
