import React from "react";
import Plan0800 from "../component/Plan0800";

const Plan0800Page = () => {
  return (
    <div>
      <Plan0800 />
    </div>
  );
};

export default Plan0800Page;
