import React from "react";
import Numbers0300 from "../../component/ControlPanel/Numbers0300";

const Numbers0300Page = () => {
  return (
    <div>
      <Numbers0300 />
    </div>
  );
};

export default Numbers0300Page;
