import React from "react";
import FrontNavbar from "../essentials/FrontNavbar";
import HeroSection from "./HeroSection";
import FrontFooter from "../essentials/FrontFooter";

const MainPage = () => {
  return (
    <div style={{ overflow: "hidden" }}>
      <FrontNavbar />
      <HeroSection />
      <FrontFooter />
    </div>
  );
};

export default MainPage;
