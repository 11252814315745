import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import Pagination from "../../examples/Pagination";
import Sidebar from "../ControlPanel/Sidebar";
import Navbar from "../ControlPanel/Navbar";
import { loadStripe } from "@stripe/stripe-js";

// Replace with your actual Stripe public key
const stripePromise = loadStripe(
  "pk_live_51NIuvRELKdOM3HNefMdJ7x2SG1fQtz7NXJElWU9dHFaulGE9Smr4cDNH4r1JOF1hd0xllUOtZJRBmvEN9M0x5QjF00RKHdARuz"
);
const LandLine = () => {
  const navigate = useNavigate();
  const [callType, setCallType] = useState("Receive");
  const [tailor, setTailor] = useState("");
  const [tariffs, setTariffs] = useState({});
  const [whisperPrice, setWhisperPrice] = useState("");
  const [vsbPrice, setVsbPrice] = useState("");
  const [partialNumbers, setPartialNumbers] = useState({});
  const [category, setCategory] = useState("ALL");
  const [tailorPrice, setTailorPrice] = useState(0);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNumber, setCurrentNumber] = useState("");
  const [currentNumberPrice, setCurrentNumberPrice] = useState("");
  const [destination, setDestination] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [tariffPrice, setTariffPrice] = useState(0);
  const [tariff, setTariff] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numbersPerPage] = useState(84);
  // State to manage the selected number
  const [selectedNumber, setSelectedNumber] = useState({
    number: "",
    price: "",
  });

  // Function to open the modal with the selected number
  const openModal = (number, price) => {
    setSelectedNumber({ number, price });
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchAllPrices = async () => {
      try {
        const [tariffResponse, whisperResponse, vsbResponse] =
          await Promise.all([
            axios.get(`https://back.faithlandline.co.uk/api/numbers/tariffs/prices`),
            axios.get(`https://back.faithlandline.co.uk/api/numbers/whisper/prices`),
            axios.get(`https://back.faithlandline.co.uk/api/numbers/vsb/prices`),
          ]);
        const whisperPriceString = whisperResponse.data.price;
        const vsbPriceString = vsbResponse.data.price;
        const parsedWhisperPrice = parseFloat(
          whisperPriceString.replace("£", "")
        );
        const parsedVsbPrice = parseFloat(vsbPriceString.replace("£", ""));

        setTariffs(tariffResponse.data);
        setWhisperPrice(parsedWhisperPrice);
        console.log(whisperResponse.data.price);
        setVsbPrice(parsedVsbPrice);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchAllPrices();
  }, []);

  const mapTariffToPlan = (tariffData) => {
    if (!tariffData || !tariffData.TF1 || !tariffData.TF1[0]) {
      return [];
    }

    return [
      {
        name: "Basic",
        value: "TF1",
        price: `${tariffData.TF1[0].Price}`,
        features: [
          `Divert To Landlines & Mobiles`,
          `${tariffData.TF1[0].inclusive} Inclusive Diversion Mins`,
          `${tariffData.TF1[0]["PPM Landline"]} ppm to landlines after inclusive`,
          "1 Month Rolling Contract",
          "Free Setup & Connection",
          "No Hidden Costs",
        ],
      },
      {
        name: "Growing Business",
        value: "TF2",
        price: `${tariffData.TF2?.[0]?.Price || "N/A"}`,
        features: [
          `Divert To Landlines & Mobiles`,
          `${tariffData.TF2?.[0]?.inclusive || "N/A"} Inclusive Diversion Mins`,
          `${
            tariffData.TF2?.[0]?.["PPM Landline"] || "N/A"
          } ppm to landlines after inclusive`,
          "1 Month Rolling Contract",
          "Free Setup & Connection",
          "No Hidden Costs",
        ],
      },
      {
        name: "Enterprise",
        value: "TF3",
        price: `${tariffData.TF3?.[0]?.Price || "N/A"}`,
        features: [
          `2000 Inbound Mins To Mobile App`,
          `${tariffData.TF3?.[0]?.inclusive || "N/A"} Diversion Mins`,
          `${
            tariffData.TF3?.[0]?.["PPM Landline"] || "N/A"
          } ppm to landlines after inclusive`,
          "1 Month Rolling Contract",
          "Free Setup & Connection",
          "No Hidden Costs",
          "PLUS These Premium Features",
        ],
        premiumFeatures: [
          "Dedicated VoIP App With:",
          "Outbound Calling",
          "Call Transfer",
          "On Hold Music",
          "Conference Calling",
          "On Demand Audio Saving",
          "Call Whisper",
          "Virtual Switchboard",
          "No Hidden Costs",
        ],
      },
    ];
  };

  const plans = mapTariffToPlan(tariffs);

  const fetchPartialNumbers = async () => {
    try {
      const response = await axios.get(
        `https://back.faithlandline.co.uk/api/numbers/partial/01`
      );
      setPartialNumbers(response.data);
      setShowButton(false);
    } catch (error) {
      console.error("Error fetching partial numbers:", error);
    }
  };

  const handleAddNumber = (number, price) => {
    setCurrentNumber(number);
    setCurrentNumberPrice(price);
    setIsModalOpen(true);
    setTailor("");
  };

  const handleConfirmNumber = () => {
    setSelectedNumbers([
      ...selectedNumbers,
      {
        number: currentNumber,
        price: currentNumberPrice,
        destination,
        tariff: tariff,
        tariffPrice: tariffPrice,
        tailorPrice: tailorPrice,
      },
    ]);
    setIsModalOpen(false);
    setDestination("");
    setCurrentNumber(null);
    setPartialNumbers({});
    setShowButton(true);
  };

  const calculateTotalPrice = () => {
    return selectedNumbers.reduce((total, number) => {
      const numberPrice = parseFloat(number.price) || 0;
      const tariffPrice = parseFloat(number.tariff) || 0;
      const tailorPrice = parseFloat(number.tailorPrice) || 0;
      return total + numberPrice + tariffPrice + tailorPrice;
    }, 0);
  };

 
  const handleProceedToPayment = async () => {
    const subtotal = calculateTotalPrice();
    const vat = parseFloat((subtotal * 0.2).toFixed(2));
    const total = subtotal + vat + parseFloat(tailorPrice);

    try {
      // Save payment details in localStorage
      localStorage.setItem(
        "paymentDetails",
        JSON.stringify({
          amount: total.toFixed(2),
          currency: "usd", // or any currency you're using
          numbers: selectedNumbers,
          subtotal: subtotal.toFixed(2),
          vat,
          tailorPrice: tailorPrice.toFixed(2),
        })
      );

      // Proceed with payment processing
      const response = await fetch("https://back.faithlandline.co.uk/api/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: total.toFixed(2),
          currency: "usd",
          numbers: selectedNumbers,
          subtotal: subtotal.toFixed(2),
          vat,
          tailorPrice: tailorPrice.toFixed(2),
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Assuming you're getting a sessionId to redirect the user
        const { sessionId } = data;
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId });
      } else {
        console.error("Error processing payment:", data.error);
        // Handle the error (e.g., show a notification to the user)
      }
    } catch (error) {
      console.error("Error occurred during payment processing:", error.message);
      // Handle the error (e.g., show a notification to the user)
    }
  };


  const filteredNumbers = Object.entries(partialNumbers).filter(
    ([number, price]) => {
      if (category === "ALL") return true;
      if (category === "Bronze" && price === "0") return true;
      if (category === "Silver" && price === "50") return true;
      if (category === "Gold" && price === "200") return true;
      if (category === "Platinum" && price === "1300") return true;
      return false;
    }
  );

  const TariffHandler = (e, tariffdata, price) => {
    e.preventDefault();
    if (tariff === tariffdata) {
      setTariff("");
      setTariffPrice(0);
    } else {
      setTariff(tariffdata);
      setTariffPrice(price);
    }
  };
  const TailorHandler = (e, tailordata, price, index) => {
    e.preventDefault();
    if (tailor === tailordata) {
      setTailor("");
      setTailorPrice();
    } else {
      setTailor(tailordata);
      setTailorPrice(price);
    }
  };

  const indexOfLastNumber = currentPage * numbersPerPage;
  const indexOfFirstNumber = indexOfLastNumber - numbersPerPage;
  const currentNumbers = filteredNumbers.slice(
    indexOfFirstNumber,
    indexOfLastNumber
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  function getCategoryBadge(price) {
    if (price >= 1300) {
      console.log("platinum");

      return "platinum";
    } else if (price >= 200) {
      return "gold";
    } else if (price >= 50) {
      return "silver";
    } else {
      return "bronze";
    }
  }

  return (
    <>
      <Sidebar />
      <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />

        <div className="main-page">
          <div className="borderline w-full h-[1px]"></div>

          <section id="chooseNumber" class="container my-5">
            <h2 class="text-center fw-bold mb-5 text-dark">
              Step 1: What Type Of Service Do You Need?
            </h2>
            <div class="row justify-content-center">
              <div class="col-md-5 mb-4">
                <div class="card h-100 shadow-lg">
                  <div class="card-body">
                    <h3 class="card-title text-center">Receive Calls</h3>
                    <p class="card-text text-center text-muted">
                      Our Classic Virtual Numbers
                    </p>
                    <p class="card-text text-center text-muted">
                      Professional Business Phone Numbers. Simple, Affordable,
                      Effective
                    </p>
                    <ul class="list-unstyled">
                      <li class="d-flex align-items-start mb-3">
                        <span class="badge bg-primary me-3"></span>
                        <div>Over 10 Completely Free Features</div>
                      </li>
                      <li class="d-flex align-items-start mb-3">
                        <span class="badge bg-primary me-3"></span>
                        <div>Send Calls To Your Mobiles & Landlines</div>
                      </li>
                      <li class="d-flex align-items-start mb-3">
                        <span class="badge bg-primary me-3"></span>
                        <div>
                          24/7 Management With Our Free Control Panel & App
                        </div>
                      </li>
                    </ul>
                    <a href="#choosePrice" class="text-decoration-none">
                      <button class="btn btn-outline-primary w-100">
                        Choose Your Number
                      </button>
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-md-5 mb-4">
                <div class="card h-100 shadow-lg">
                  <div class="card-body">
                    <h3 class="card-title text-center">Make & Receive Calls</h3>
                    <p class="card-text text-center text-muted">
                      Our Complete VoIP System
                    </p>
                    <p class="card-text text-center text-muted">
                      Professional Business Phone Numbers. Simple, Affordable,
                      Effective
                    </p>
                    <ul class="list-unstyled">
                      <li class="d-flex align-items-start mb-3">
                        <span class="badge bg-primary me-3"></span>
                        <div>All Our Classic Free Virtual Number Features</div>
                      </li>
                      <li class="d-flex align-items-start mb-3">
                        <span class="badge bg-primary me-3"></span>
                        <div>
                          Fully Dedicated VoIP App to Make Outbound Calls From
                          Your Number
                        </div>
                      </li>
                      <li class="d-flex align-items-start mb-3">
                        <span class="badge bg-primary me-3"></span>
                        <div>
                          Transfer Calls, On Hold Music, Conference Calling -
                          All Included Free
                        </div>
                      </li>
                    </ul>
                    <a href="#choosePrice" class="text-decoration-none">
                      <button class="btn btn-outline-primary w-100">
                        Choose Your Number
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="choosePrice" className="container py-5">
            <h2 className="text-center bg-clip-text bg-gradient-to-t from-pink-700 to-pink-600 text-black">
              Step 2: Select Your Tariff
            </h2>
            <p className="text-center text-muted mb-4">
              Choose A Tariff With The Amount Of Minutes You Need Each Month Per
              User
            </p>
            <div className="row justify-content-center">
              {plans.length > 0 ? (
                plans.map((plan, index) => (
                  <div
                    key={index}
                    className={`col-lg-3 col-md-6 mb-4 ${
                      index === 2 ? "h-100" : ""
                    } card shadow-sm border-0`}
                  >
                    <div className="card-body">
                      <h3 className="card-title text-center">{plan.name}</h3>
                      <p className="card-text text-center display-4">
                        {plan.price}
                      </p>
                      <b className="d-block text-center mb-4">/Month +VAT</b>
                      <ul className="list-group list-group-flush mb-4">
                        {plan.features.map((feature, idx) => (
                          <li key={idx} className="list-group-item">
                            <i className="bi bi-check-circle-fill text-success"></i>
                            <span className="ms-2">{feature}</span>
                          </li>
                        ))}
                        {plan.premiumFeatures && (
                          <>
                            <p className="text-center mt-3">
                              PLUS These Premium Features
                            </p>
                            <ul className="list-group list-group-flush">
                              {plan.premiumFeatures.map((feature, idx) => (
                                <li key={idx} className="list-group-item">
                                  <i className="bi bi-star-fill text-warning"></i>
                                  <span className="ms-2">{feature}</span>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </ul>
                      <a href="#tailor" className="d-block text-center">
                        <button
                          className={`btn btn-primary w-100 ${
                            tariffPrice === plan.value
                              ? "btn-warning"
                              : "btn-primary"
                          }`}
                          onClick={(e) => {
                            fetchPartialNumbers();
                            TariffHandler(e, plan.price, plan.value, index);
                            index === 2
                              ? (() => {
                                  setButtonDisabled(!ButtonDisabled);
                                  setTailor("Switchboard");
                                })()
                              : setButtonDisabled(false);
                          }}
                        >
                          Build my Number
                        </button>
                      </a>
                    </div>
                  </div>
                ))
              ) : (
                <p>Loading tariffs...</p>
              )}
            </div>
          </section>

          <section id="tailor" className="container py-5">
            <h2 className="text-center text-black bg-clip-text bg-gradient-to-t from-pink-700 to-pink-600">
              Step 3: Tailor Your Number
            </h2>
            <div className="row justify-content-center">
              <div className="col-md-6 mb-4 card shadow-sm border-0">
                <div className="card-body text-center">
                  <h3 className="card-title">Call Whisper</h3>
                  <p className="card-text">"Incoming Business Call"</p>
                  <p className="font-weight-bold text-muted py-3">
                    What's Included
                  </p>
                  <ul className="list-group list-group-flush mb-4">
                    <li className="list-group-item">
                      <i className="bi bi-check-circle-fill text-success"></i>
                      <span className="ms-2">Call Whisper</span>
                    </li>
                    <li className="list-group-item">
                      <i className="bi bi-check-circle-fill text-success"></i>
                      <span className="ms-2">Caller Announcement</span>
                    </li>
                    <li className="list-group-item">
                      <i className="bi bi-check-circle-fill text-success"></i>
                      <span className="ms-2">Press 1 To Accept A Call</span>
                    </li>
                  </ul>
                  <p className="font-weight-bold text-muted py-3">
                    {whisperPrice} Per Month +VAT
                  </p>
                  <button
                    className={`btn w-100 ${
                      tailor === "Whisper" || tailor === "Switchboard"
                        ? "btn-warning"
                        : "btn-outline-primary"
                    }`}
                    onClick={(e) =>
                      TailorHandler(e, "Whisper", whisperPrice, 1)
                    }
                    disabled={ButtonDisabled}
                  >
                    {tailor === "Whisper" ? (
                      <span>Selected</span>
                    ) : tailor === "Switchboard" ? (
                      <span>Included with Virtual Switchboard</span>
                    ) : tariff === "TF3" ? (
                      <span>Included With Unlimited</span>
                    ) : (
                      <span>Add to your number</span>
                    )}
                  </button>
                </div>
              </div>
              <div className="col-md-6 mb-4 card shadow-sm border-0">
                <div className="card-body text-center">
                  <h3 className="card-title">Virtual Switchboard</h3>
                  <p className="card-text">
                    "Press 1 For Sales, 2 For Accounts..."
                  </p>
                  <p className="font-weight-bold text-muted py-3">
                    What's Included
                  </p>
                  <ul className="list-group list-group-flush mb-4">
                    <li className="list-group-item">
                      <i className="bi bi-check-circle-fill text-success"></i>
                      <span className="ms-2">Virtual Switchboard</span>
                    </li>
                    <li className="list-group-item">
                      <i className="bi bi-check-circle-fill text-success"></i>
                      <span className="ms-2">Up To 10 Menu Options</span>
                    </li>
                    <li className="list-group-item">
                      <i className="bi bi-check-circle-fill text-success"></i>
                      <span className="ms-2">Free Professional Greeting</span>
                    </li>
                  </ul>
                  <p className="font-weight-bold text-muted py-3">
                    {vsbPrice} Per Month +VAT
                  </p>
                  <button
                    className={`btn w-100 ${
                      tailor === "Switchboard"
                        ? "btn-warning"
                        : "btn-outline-primary"
                    }`}
                    onClick={(e) =>
                      TailorHandler(e, "Switchboard", vsbPrice, 1)
                    }
                    disabled={ButtonDisabled}
                  >
                    {tailor === "Switchboard"
                      ? "Selected"
                      : "Add to your Number"}
                  </button>
                </div>
              </div>
            </div>
          </section>

          <div className="container-fluid py-4">
          {/* Filter Section */}
          <div className="row mb-4">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Select Category:</h5>
                <div className="btn-group">
                  <button
                    className={`btn ${
                      category === "ALL" ? "btn-primary" : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("ALL")}
                  >
                    All
                  </button>
                  <button
                    className={`btn ${
                      category === "Bronze"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Bronze")}
                  >
                    Bronze
                  </button>
                  <button
                    className={`btn ${
                      category === "Silver"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Silver")}
                  >
                    Silver
                  </button>
                  <button
                    className={`btn ${
                      category === "Gold"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Gold")}
                  >
                    Gold
                  </button>
                  <button
                    className={`btn ${
                      category === "Platinum"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Platinum")}
                  >
                    Platinum
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Cards Section */}
          <div className="row">
            {currentNumbers.map(([number, price]) => (
              <div className="col-lg-4 col-md-6 mb-4" key={number}>
                <div
  className="card h-100 border-0 shadow-lg rounded-lg position-relative card-hover"
  style={{ overflow: 'hidden', transition: 'all 0.3s ease-in-out' }}
>
  {/* Card Content */}
  <div className="card-body d-flex justify-content-between align-items-center p-4">
    {/* Number Display */}
    <h5 className="card-title font-weight-bold mb-0">{number}</h5>

    {/* Category Badge */}
    <span
  className={`badge fs-6 px-3 py-2 ${
    getCategoryBadge(price) === "platinum"
      ? "bg-gradient-info"
      : getCategoryBadge(price) === "gold"
      ? "bg-gradient-warning"
      : getCategoryBadge(price) === "silver"
      ? "bg-gradient-success"
      : "bg-gradient-secondary"
  } custom-badge`}
>
  {getCategoryBadge(price)}
</span>

<style jsx>{`
  .custom-badge {
    font-size: 10px;  
  }
`}</style>

  </div>

  {/* Hover Elements (Price & Add Button) */}
  <div
    className="card-hover-content position-absolute d-flex flex-column align-items-center justify-content-center w-100 h-100"
    style={{
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      opacity: '0',
      transition: 'opacity 0.3s ease-in-out',
      color: 'white',
    }}
  >
    {/* Price Display */}
    <div className="mb-3">
      <span className="badge bg-primary fs-5 px-3 py-2">£{price}</span>
    </div>

    {/* Add Button */}
    <button
      className="btn btn-light btn-sm w-75 py-2"
      onClick={() =>
        handleAddNumber(number, price, getCategoryBadge(price))
      }
      style={{color:"black"}}
    >
      Add
    </button>
  </div>

  {/* Hover Effect */}
  <style jsx>{`
    .card:hover .card-hover-content {
      opacity: 1;
    }
  `}</style>
</div>

              </div>
            ))}
          </div>

          {/* Pagination */}
          <Pagination
            itemsPerPage={numbersPerPage}
            totalItems={filteredNumbers.length}
            paginate={paginate}
            currentPage={currentPage}
          />

          {/* Selected Numbers Section */}
          {selectedNumbers.length > 0 && (
            <div className="my-5">
              <h3>Selected Numbers:</h3>
              <ul>
                {selectedNumbers.map(({ number, destination, price }) => (
                  <li key={number}>
                    {number} - Destination: {destination} - £{price}
                  </li>
                ))}
              </ul>
              <button
                className="mt-3 btn btn-info text-white py-2 px-4 rounded"
                onClick={handleProceedToPayment}
              >
                Proceed to Payment
              </button>
            </div>
          )}

          {/* Modal */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            ariaHideApp={false}
            contentLabel="Enter Destination"
            className="custom-modal-content"
            overlayClassName="custom-modal-overlay"
          >
            <h2 className="modal-title">
              Enter Destination for {currentNumber}
            </h2>
            <input
              type="text"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              placeholder="Enter destination"
              className="form-control"
            />
            <div className="modal-buttons">
              <button className="btn btn-primary" onClick={handleConfirmNumber}>
                Confirm
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </div>
        </div>
      </main>
    </>
  );
};

export default LandLine;

// const plans2 = [
//   {
//     name: "Startup",
//     price: "£9",
//     features: [
//       "500 Inbound Mins To Mobile App",
//       "500 Diversion Mins",
//       "500 Outbound Mins",
//       "1 Month Rolling Contract",
//       "Free Setup & Connection",
//       "No Hidden Costs",
//       "Price Per User",
//     ],
//     freeFeatures: ["Includes Our Supercharged FREE Features"],
//     premiumFeatures: [
//       "Dedicated VoIP App With:",
//       "Outbound Calling",
//       "Call Transfer",
//       "On Hold Music",
//       "Conference Calling",
//       "On Demand Audio Saving",
//     ],
//   },
//   {
//     name: "Growing Business",
//     price: "£15",
//     features: [
//       "1000 Inbound Mins To Mobile App",
//       "1000 Diversion Mins",
//       "1000 Outbound Mins",
//       "1 Month Rolling Contract",
//       "Free Setup & Connection",
//       "No Hidden Costs",
//       "Price Per User",
//     ],
//     freeFeatures: ["Includes Our Supercharged FREE Features"],
//     premiumFeatures: [
//       "Dedicated VoIP App With:",
//       "Outbound Calling",
//       "Call Transfer",
//       "On Hold Music",
//       "Conference Calling",
//       "On Demand Audio Saving",
//     ],
//   },
//   {
//     name: "Enterprise",
//     price: "£30",
//     features: [
//       "2000 Inbound Mins To Mobile App",
//       "2000 Diversion Mins",
//       "2000 Outbound Mins",
//       "1 Month Rolling Contract",
//       "Free Setup & Connection",
//       "No Hidden Costs",
//     ],
//     freeFeatures: ["Includes Our Supercharged FREE Features"],
//     premiumFeatures: [
//       "Dedicated VoIP App With:",
//       "Outbound Calling",
//       "Call Transfer",
//       "On Hold Music",
//       "Conference Calling",
//       "On Demand Audio Saving",
//       "Call Whisper",
//       "Virtual Switchboard",
//       "Tamar Voice AI",
//     ],
//     newList: [
//       "24/7 Customer Support",
//       "Advanced Analytics Dashboard",
//       "Priority Technical Assistance",
//       "Custom API Integrations",
//     ],
//   },
// ];
