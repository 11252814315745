import React, { useState } from "react";
import { Tab, Tabs, Table } from "react-bootstrap";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";

const TamarVoip = () => {
  const [activeKey, setActiveKey] = useState("extensions");

  return (
    <div>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />
        <div className="container pt-4">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <Tabs
                id="voip-tabs"
                activeKey={activeKey}
                onSelect={(k) => setActiveKey(k)}
                className="mb-3 justify-content-center"
              >
                <Tab
                  eventKey="extensions"
                  title={
                    <span>
                      Extensions <i className="fas fa-phone"></i>
                    </span>
                  }
                >
                  <div className="pt-4">
                    <h3>Your VoIP Numbers</h3>
                    <p>Below are your VoIP extensions</p>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>VoIP Number</th>
                          <th>Description</th>
                          <th>Quick Login</th>
                          <th>Login Details</th>
                          <th>Manage</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="5" className="text-center">
                            No VoIP extensions have been created yet
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </Tab>

                <Tab
                  eventKey="upgrade"
                  title={
                    <span>
                      Upgrade To VoIP <i className="fas fa-arrow-up"></i>
                    </span>
                  }
                >
                  <div className="pt-4">
                    <h3>Your Complete Business Phone System</h3>
                    <p>
                      VoIP allows you to make and receive calls from your Tamar
                      Numbers over the internet. Packed with extra features like
                      On Demand Call Recording, Call Transfer & Hold Music, our
                      VoIP system lets you manage your business comms better
                      than ever.
                    </p>
                    <p>
                      Upgrading your existing Tamar Numbers to VoIP is easy. We
                      simply need to upgrade your current tariff to one of our
                      new VoIP inclusive tariffs.{" "}
                      <strong>
                        Enter your preferred contact name and number below and
                        one of our team will be in touch to get everything set
                        up for you!
                      </strong>
                    </p>

                    <form>
                      <div className="form-group mb-3">
                        <label>Contact Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter contact name"
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label>Your Telephone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter phone number"
                        />
                      </div>

                      <button className="btn btn-primary">Call me Back</button>
                    </form>
                  </div>
                </Tab>

                <Tab
  eventKey="download"
  title={
    <span>
      Download App <i className="fas fa-download"></i>
    </span>
  }
>
  <div className="pt-4">
    <h3>Download App</h3>
    <p>
      Tamar VoIP allows you to use any VoIP app or phone, we provide a simple
      to use VoIP app below.
    </p>

    <div className="d-flex justify-content-around">
      <div className="text-center">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
          alt="QR Code Android"
          className="img-fluid mb-2"
        />
        <p>Download for Android</p>
      </div>

      <div className="text-center">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/d/d0/QR_code_for_mobile_English_Wikipedia.svg"
          alt="QR Code iOS"
          className="img-fluid mb-2"
        />
        <p>Download for iOS</p>
      </div>
    </div>
  </div>
</Tab>


<Tab
  eventKey="faq"
  title={
    <span>
      FAQs & Help <i className="fas fa-question-circle"></i>
    </span>
  }
>
  <div className="pt-4">
    <h3>FAQ & Help</h3>
    <p>
      Below are answers to some of our most popular questions. If you require
      further assistance, please call 0800 772 0000 or email
      hello@tamar.co.uk.
    </p>

    <div className="accordion" id="faqAccordion">
      <div className="accordion-item">
        <h2 className="accordion-header" id="headingOne">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            Question 1
          </button>
        </h2>
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#faqAccordion"
        >
          <div className="accordion-body">
            This is the answer to question 1.
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            Question 2
          </button>
        </h2>
        <div
          id="collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="headingTwo"
          data-bs-parent="#faqAccordion"
        >
          <div className="accordion-body">
            This is the answer to question 2.
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="headingThree">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            Question 3
          </button>
        </h2>
        <div
          id="collapseThree"
          className="accordion-collapse collapse"
          aria-labelledby="headingThree"
          data-bs-parent="#faqAccordion"
        >
          <div className="accordion-body">
            This is the answer to question 3.
          </div>
        </div>
      </div>
    </div>
  </div>
</Tab>

              </Tabs>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default TamarVoip;
