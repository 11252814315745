import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const handleLogout = () => {
    localStorage.removeItem("user_id");
    navigate("/");
  };

  return (
    <aside
      className="sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-3 bg-gradient-dark"
      id="sidenav-main"
    >
      <div className="sidenav-header">
        <i
          className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
          aria-hidden="true"
          id="iconSidenav"
        ></i>
        <Link to="/control-panel" className="navbar-brand m-0">
          <img
            src="assets/img/logo-ct.png"
            className="navbar-brand-img h-100"
            alt="main_logo"
          />
          <span className="ms-1 font-weight-bold text-white">
            Control Panel
          </span>
        </Link>
      </div>
      <hr className="horizontal light mt-0 mb-2" />
      <div
        className="collapse navbar-collapse w-auto"
        id="sidenav-collapse-main"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link
              className={`nav-link text-white ${
                location.pathname === "/control-panel"
                  ? "active bg-gradient-primary"
                  : ""
              }`}
              to="/control-panel"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">dashboard</i>
              </div>
              <span className="nav-link-text ms-1">Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className={`nav-link text-white ${
                location.pathname === "/control-panel-purchased-card"
                  ? "active bg-gradient-primary"
                  : ""
              }`}
              to="/control-panel-purchased-card"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">receipt_long</i>
              </div>
              <span className="nav-link-text ms-1">Purchase Numbers</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link text-white ${
                location.pathname === "/control-panel-purchased"
                  ? "active bg-gradient-primary"
                  : ""
              }`}
              to="/control-panel-purchased"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">receipt_long</i>
              </div>
              <span className="nav-link-text ms-1">Purchased Number</span>
            </Link>
          </li>

          <li className="nav-item mt-3">
            <h6 className="ps-4 ms-2 text-uppercase text-xs text-white font-weight-bolder opacity-8">
              Account
            </h6>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link text-white ${
                location.pathname === "/control-panel-profile"
                  ? "active bg-gradient-primary"
                  : ""
              }`}
              to="/control-panel-profile"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">person</i>
              </div>
              <span className="nav-link-text ms-1">Profile</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link text-white ${
                location.pathname === "/control-panel-invoice"
                  ? "active bg-gradient-primary"
                  : ""
              }`}
              to="/control-panel-invoice"
            >
              <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                <i className="material-icons opacity-10">receipt_long</i>
              </div>
              <span className="nav-link-text ms-1">Invoice</span>
            </Link>
          </li>
        </ul>
      </div>
      <div className="sidenav-footer position-absolute w-100 bottom-0">
        <div className="mx-3">
          <a
            className="btn bg-gradient-primary w-100"
            type="button"
            onClick={handleLogout}
          >
            Logout
          </a>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
