import React from "react";
import { Link } from "react-router-dom";

const FrontNavbar = () => {
  return (
    <div>
      {/* <!-- Header Menu Area Start--> */}
      <div
        id="sticky-header"
        className="zomia_nav_manu d-md-none bg_color2 white d-lg-block d-sm-none d-none"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <div className="logo">
                <Link to="/" className="main_sticky"  title="petech">
                  <img src="assets/images/logo.png" alt="astute" />
                </Link>
              </div>
            </div>
            <div className="col-lg-8">
              <nav className="zomia_menu">
                <ul className="nav_scroll">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/services">Service</Link>
                  </li>
                  <li>
                    <Link to="/faqs">Faqs</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact</Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-lg-2">
              <div className="header-search-button">
                <div className="header-donate-btn">
                  <Link  className="header-btn" to="/login">
                    Login <i className="flaticon flaticon-right-arrow"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Header Menu Area end--> */}

      {/* <!-- Zomia Mobile Menu Area start --> */}
      <div className="mobile-menu-area d-sm-block d-md-block d-lg-none ">
        <div className="mobile-menu">
          <nav className="petech_menu">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/services">Service</Link>
              </li>
              <li>
                <Link to="/faqs">Faqs</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/* <!-- Zomia Mobile Menu Area end--> */}
    </div>
  );
};

export default FrontNavbar;
