import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import axios from "axios"; // Assuming you're using axios for API requests

const Voicemail = () => {
  const [voicemailBoxes, setVoicemailBoxes] = useState([]);
  const [selectedVoicemailBox, setSelectedVoicemailBox] = useState("");
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [newBoxName, setNewBoxName] = useState("");
  const [voicemailDetails, setVoicemailDetails] = useState(null);
  const [emails, setEmails] = useState(["", ""]); // default two email fields
  const user_id = localStorage.getItem("user_id");
  // Fetch voicemail boxes from API
  useEffect(() => {
    const fetchVoicemailBoxes = async () => {
      try {
        const response = await axios.get(
          `https://back.faithlandline.co.uk/api/voicemails/user/${user_id}`
        ); // Replace with your API endpoint
        setVoicemailBoxes(response.data);
      } catch (error) {
        console.error("Error fetching voicemail boxes:", error);
      }
    };

    fetchVoicemailBoxes();
  }, []);

  const handleCreateNew = async () => {
    try {
      // API request to create a new voicemail box
      await axios.post("https://back.faithlandline.co.uk/api/voicemails/create", {
        description: newBoxName,
        user_id: user_id,
      });
      alert("New voicemail box created!");
    } catch (error) {
      console.error("Error creating new voicemail box:", error);
    }
  };

  // Fetch details of the selected voicemail box
  const handleViewEdit = async () => {
    try {
      const response = await axios.get(
        `https://back.faithlandline.co.uk/api/voicemail-boxes/${selectedVoicemailBox}`
      );
      setVoicemailDetails(response.data);
      setEmails(response.data.emailaddresses?.split(",") || ["", ""]); // Set emails
    } catch (error) {
      console.error("Error fetching voicemail details:", error);
    }
  };
  
  const handleDelete = async () => {
    try {
      // Sending the selected voicemail box ID as a URL parameter
      await axios.delete(`https://back.faithlandline.co.uk/api/voicemails/delete/${selectedVoicemailBox}`);
  
      alert("Voicemail box deleted successfully!");
    } catch (error) {
      console.error("Error deleting voicemail box:", error);
      alert("Failed to delete voicemail box.");
    }
  };
  
  

  const addEmailField = () => setEmails([...emails, ""]);
  const removeEmailField = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };

  const updateEmailField = (index, value) => {
    const updatedEmails = [...emails];
    updatedEmails[index] = value;
    setEmails(updatedEmails);
  };

  // Handle form submission to update the voicemail box
  const handleSaveChanges = async () => {
    try {
      console.log(voicemailDetails);
      console.log(emails);

      // Send the updated data to the backend API
      const response = await axios.put(
        "https://back.faithlandline.co.uk/api/voicemails/update",
        {
          id: voicemailDetails._id,
          description: voicemailDetails.description,
          voicemail_id: voicemailDetails.voicemailId,
          email: voicemailDetails.email,
          push: voicemailDetails.push,
          voicemail: voicemailDetails.voicemail,
          emailaddresses: emails.join(","), // Comma-separated emails
          pin: voicemailDetails.pin,
          greetingid: voicemailDetails.greetingid || null, // Keep greetingid as null for now
        }
      );

      if (response.status === 200) {
        alert("Voicemail box updated successfully!");
      }
    } catch (error) {
      console.error("Error updating voicemail box:", error);
      alert("Failed to update voicemail box.");
    }
  };

  return (
    <div>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-10 mx-auto">
              {/* Card Design */}
              <div className="card shadow-lg border-0 rounded-lg">
                <div className="card-body">
                  {/* Title */}
                  <h3 className="card-title">
                    Select an existing voicemail box or create new
                  </h3>
                  <p className="text-muted mb-4">
                    To view or make changes to an existing voicemail box, select
                    the name from the dropdown and click View/Edit. To create a
                    new box, select Create new.
                  </p>

                  {/* Dropdown to select voicemail box */}
                  <div className="mb-4">
                    <select
                      className="form-select form-select-lg mb-3"
                      value={selectedVoicemailBox} // The value will be the selected ID
                      onChange={(e) => setSelectedVoicemailBox(e.target.value)} // Update the selectedVoicemailBox with the ID
                    >
                      <option value="">Select Voicemail Box</option>
                      {voicemailBoxes.map((box) => (
                        <option key={box.voicemailId} value={box.voicemailId}>
                          {box.description}{" "}
                          {/* Display the description, but value is the ID */}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Buttons for View/Edit and Create New */}
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-primary"
                      onClick={handleViewEdit}
                      disabled={!selectedVoicemailBox}
                    >
                      View/Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={handleDelete}
                      disabled={!selectedVoicemailBox}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-success"
                      onClick={() => setShowCreateNew(true)}
                    >
                      Create New
                    </button>
                  </div>

                  {/* Section for View/Edit */}

                  {!showCreateNew && voicemailDetails && (
                    <div className="mt-4 row">
                      {/* Voicemail Box Config */}
                      <div className="col-md-6">
                        <div className="card shadow border-0 p-4">
                          <h5>Voicemail Box Selected</h5>
                          <p>
                            Selected Voicemail:{" "}
                            {voicemailDetails?.description ?? "Dummy Voicemail"}
                          </p>
                          <label>Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={
                              voicemailDetails?.description ?? "Dummy Voicemail"
                            }
                            readOnly
                          />

                          <label className="mt-3">Greeting:</label>
                          <select
                            className="form-select"
                            value={voicemailDetails?.greetingid ?? ""}
                            onChange={(e) =>
                              setVoicemailDetails({
                                ...voicemailDetails,
                                greetingid: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Greeting</option>
                            <option value="generic">Generic (default)</option>
                            {/* You can dynamically load other greeting options here */}
                          </select>

                          <button className="btn btn-secondary mt-2">
                            Edit Greeting
                          </button>

                          <label className="mt-3">PIN:</label>
                          <input
                            type="password"
                            className="form-control"
                            value={voicemailDetails?.pin}
                            onChange={(e) =>
                              setVoicemailDetails({
                                ...voicemailDetails,
                                pin: e.target.value,
                              })
                            }
                          />

                          <div className="card mt-3">
                            <h6 className="text-center">
                              Administration Number
                            </h6>
                            <p className="text-center">0333 772 1571</p>
                          </div>

                          {/* Checkboxes */}
                          <div className="form-check mt-3 d-flex">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={voicemailDetails?.voicemail === "on"}
                              onChange={(e) =>
                                setVoicemailDetails({
                                  ...voicemailDetails,
                                  voicemail: e.target.checked ? "on" : "off",
                                })
                              }
                            />
                            <label className="form-check-label mx-2 mt-1">
                              Allow caller to leave a message
                            </label>
                          </div>
                          <div className="form-check d-flex">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={voicemailDetails?.email === "on"}
                              onChange={(e) =>
                                setVoicemailDetails({
                                  ...voicemailDetails,
                                  email: e.target.checked ? "on" : "off",
                                })
                              }
                            />
                            <label className="form-check-label mx-2 mt-1">
                              Email me even if the caller does not leave a
                              message
                            </label>
                          </div>
                          <div className="form-check d-flex">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={voicemailDetails?.push === "on"}
                              onChange={(e) =>
                                setVoicemailDetails({
                                  ...voicemailDetails,
                                  push: e.target.checked ? "on" : "off",
                                })
                              }
                            />
                            <label className="form-check-label mx-2 mt-1">
                              Send a push notification (via Apple & Android
                              devices)
                            </label>
                          </div>

                          {/* Emails */}
                          <label className="mt-3">
                            Received voicemails will be sent to the following
                            email address(es):
                          </label>
                          {emails.map((email, index) => (
                            <div key={index} className="d-flex mb-2">
                              <input
                                type="email"
                                className="form-control me-2"
                                value={email}
                                onChange={(e) =>
                                  updateEmailField(index, e.target.value)
                                }
                              />
                              {emails.length > 2 && (
                                <button
                                  className="btn btn-danger"
                                  onClick={() => removeEmailField(index)}
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            className="btn btn-secondary"
                            onClick={addEmailField}
                          >
                            Add Email
                          </button>

                          <div className="text-end mt-4">
                            <button
                              className="btn btn-primary"
                              onClick={handleSaveChanges}
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Recent Voicemails */}
                      <div className="col-md-6">
                        <div className="card shadow border-0 p-4">
                          <h5>Recent Voicemails</h5>
                          <p>
                            Use the controls below to listen to your recent
                            voicemails.
                          </p>

                          {/* Audio Player */}
                          <div className="audio-player">
                            <audio controls>
                              <source
                                src={
                                  voicemailDetails?.voicemailUrl ??
                                  "/path/to/dummy-voicemail.mp3"
                                }
                                type="audio/mpeg"
                              />
                              Your browser does not support the audio element.
                            </audio>
                            <div className="d-flex justify-content-between mt-2">
                              <button className="btn btn-primary">
                                Backward
                              </button>
                              <button className="btn btn-secondary">
                                Play
                              </button>
                              <button className="btn btn-primary">
                                Forward
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Section for Create New */}
                  {showCreateNew && (
                    <div className="mt-4 card shadow border-0 p-4">
                      <h4>Create a new voicemail box</h4>
                      <p className="text-muted mb-3">
                        To create a new voicemail box, enter a description for
                        the box and select Create.
                      </p>

                      <h6>Description:</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name for this voicemail box"
                        value={newBoxName}
                        onChange={(e) => setNewBoxName(e.target.value)}
                      />

                      <div className="text-end mt-3">
                        <button
                          className="btn btn-primary"
                          onClick={handleCreateNew}
                        >
                          Create
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Voicemail;
