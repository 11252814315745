import React from "react";
import Sidebar from "../../Sidebar";
import Navbar from "../../Navbar";
import Footer from "../../Footer";

const MainComponent = () => {
  return (
    <div>
      <Sidebar />

      <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />

        <div class="container-fluid py-4">
          <div class="row">
            <div class="col-12">
              <div class="card my-4">
                <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                    <h6 class="text-white text-capitalize ps-3">
                      Purchased Numbers
                    </h6>
                  </div>
                </div>
                <div class="card-body px-0 pb-2">
                  <div class="table-responsive p-0">
                    <table class="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Author
                          </th>
                          <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                            Function
                          </th>
                          <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Status
                          </th>
                          <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Employed
                          </th>
                          <th class="text-secondary opacity-7"></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div class="d-flex px-2 py-1">
                              <div>
                                <img
                                  src="assets/img/team-2.jpg"
                                  class="avatar avatar-sm me-3 border-radius-lg"
                                  alt="user1"
                                />
                              </div>
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">John Michael</h6>
                                <p class="text-xs text-secondary mb-0">
                                  <a
                                    href="https://demos.creative-tim.com/cdn-cgi/l/email-protection"
                                    class="__cf_email__"
                                    data-cfemail="b9d3d6d1d7f9dacbdcd8cdd0cfdc94cdd0d497dad6d4"
                                  >
                                    [email&#160;protected]
                                  </a>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">Manager</p>
                            <p class="text-xs text-secondary mb-0">
                              Organization
                            </p>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="badge badge-sm bg-gradient-success">
                              Online
                            </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">
                              23/04/18
                            </span>
                          </td>
                          <td class="align-middle">
                            <a
                              href="javascript:;"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"
                              data-original-title="Edit user"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex px-2 py-1">
                              <div>
                                <img
                                  src="assets/img/team-3.jpg"
                                  class="avatar avatar-sm me-3 border-radius-lg"
                                  alt="user2"
                                />
                              </div>
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Alexa Liras</h6>
                                <p class="text-xs text-secondary mb-0">
                                  <a
                                    href="https://demos.creative-tim.com/cdn-cgi/l/email-protection"
                                    class="__cf_email__"
                                    data-cfemail="4e2f222b362f0e2d3c2b2f3a27382b633a2723602d2123"
                                  >
                                    [email&#160;protected]
                                  </a>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              Programator
                            </p>
                            <p class="text-xs text-secondary mb-0">Developer</p>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="badge badge-sm bg-gradient-secondary">
                              Offline
                            </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">
                              11/01/19
                            </span>
                          </td>
                          <td class="align-middle">
                            <a
                              href="javascript:;"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"
                              data-original-title="Edit user"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex px-2 py-1">
                              <div>
                                <img
                                  src="assets/img/team-4.jpg"
                                  class="avatar avatar-sm me-3 border-radius-lg"
                                  alt="user3"
                                />
                              </div>
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Laurent Perrier</h6>
                                <p class="text-xs text-secondary mb-0">
                                  <a
                                    href="https://demos.creative-tim.com/cdn-cgi/l/email-protection"
                                    class="__cf_email__"
                                    data-cfemail="0864697d7a6d667c486b7a6d697c617e6d257c6165266b6765"
                                  >
                                    [email&#160;protected]
                                  </a>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              Executive
                            </p>
                            <p class="text-xs text-secondary mb-0">Projects</p>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="badge badge-sm bg-gradient-success">
                              Online
                            </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">
                              19/09/17
                            </span>
                          </td>
                          <td class="align-middle">
                            <a
                              href="javascript:;"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"
                              data-original-title="Edit user"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex px-2 py-1">
                              <div>
                                <img
                                  src="assets/img/team-3.jpg"
                                  class="avatar avatar-sm me-3 border-radius-lg"
                                  alt="user4"
                                />
                              </div>
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Michael Levi</h6>
                                <p class="text-xs text-secondary mb-0">
                                  <a
                                    href="https://demos.creative-tim.com/cdn-cgi/l/email-protection"
                                    class="__cf_email__"
                                    data-cfemail="f19c98929990949db19283949085988794dc85989cdf929e9c"
                                  >
                                    [email&#160;protected]
                                  </a>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              Programator
                            </p>
                            <p class="text-xs text-secondary mb-0">Developer</p>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="badge badge-sm bg-gradient-success">
                              Online
                            </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">
                              24/12/08
                            </span>
                          </td>
                          <td class="align-middle">
                            <a
                              href="javascript:;"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"
                              data-original-title="Edit user"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex px-2 py-1">
                              <div>
                                <img
                                  src="assets/img/team-2.jpg"
                                  class="avatar avatar-sm me-3 border-radius-lg"
                                  alt="user5"
                                />
                              </div>
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Richard Gran</h6>
                                <p class="text-xs text-secondary mb-0">
                                  <a
                                    href="https://demos.creative-tim.com/cdn-cgi/l/email-protection"
                                    class="__cf_email__"
                                    data-cfemail="dfadb6bcb7beadbb9fbcadbabeabb6a9baf2abb6b2f1bcb0b2"
                                  >
                                    [email&#160;protected]
                                  </a>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">Manager</p>
                            <p class="text-xs text-secondary mb-0">Executive</p>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="badge badge-sm bg-gradient-secondary">
                              Offline
                            </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">
                              04/10/21
                            </span>
                          </td>
                          <td class="align-middle">
                            <a
                              href="javascript:;"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"
                              data-original-title="Edit user"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div class="d-flex px-2 py-1">
                              <div>
                                <img
                                  src="assets/img/team-4.jpg"
                                  class="avatar avatar-sm me-3 border-radius-lg"
                                  alt="user6"
                                />
                              </div>
                              <div class="d-flex flex-column justify-content-center">
                                <h6 class="mb-0 text-sm">Miriam Eric</h6>
                                <p class="text-xs text-secondary mb-0">
                                  <a
                                    href="https://demos.creative-tim.com/cdn-cgi/l/email-protection"
                                    class="__cf_email__"
                                    data-cfemail="177a7e657e767a5774657276637e61723a637e7a3974787a"
                                  >
                                    [email&#160;protected]
                                  </a>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p class="text-xs font-weight-bold mb-0">
                              Programator
                            </p>
                            <p class="text-xs text-secondary mb-0">Developer</p>
                          </td>
                          <td class="align-middle text-center text-sm">
                            <span class="badge badge-sm bg-gradient-secondary">
                              Offline
                            </span>
                          </td>
                          <td class="align-middle text-center">
                            <span class="text-secondary text-xs font-weight-bold">
                              14/09/20
                            </span>
                          </td>
                          <td class="align-middle">
                            <a
                              href="javascript:;"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"
                              data-original-title="Edit user"
                            >
                              Edit
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default MainComponent;
