import React from "react";
import FrontFooter from "../essentials/FrontFooter";
import FrontNavbar from "../essentials/FrontNavbar";
import HeroSection from "./HeroSection";

const Services = () => {
  return (
    <div>
      <FrontNavbar />
      <HeroSection />
      <FrontFooter />
    </div>
  );
};

export default Services;
