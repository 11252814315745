import React from "react";
import BlackList from "../../component/ControlPanel/BlackList";

const BlackListPage = () => {
  return (
    <div>
      <BlackList />
    </div>
  );
};

export default BlackListPage;
