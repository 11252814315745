import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { loadStripe } from "@stripe/stripe-js";
// Replace with your actual Stripe public key
const stripePromise = loadStripe(
  "pk_live_51NIuvRELKdOM3HNefMdJ7x2SG1fQtz7NXJElWU9dHFaulGE9Smr4cDNH4r1JOF1hd0xllUOtZJRBmvEN9M0x5QjF00RKHdARuz"
);



const HeroSection = () => {
  const [email, setEmail] = useState(""); // Update the state to store email instead of username
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://back.faithlandline.co.uk/api/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }), // Use email here
      });

      const data = await response.json();

      if (response.ok) {
        toast.success("User logged in successfully");
        localStorage.setItem("token", data.token);
        Cookies.set("user_id", data.user.id, { expires: 1 });
        Cookies.set("token", data.token, { expires: 1 });
        localStorage.setItem("user_id", data.user.id);
        localStorage.setItem("token", data.token);

        const paymentDetails = JSON.parse(localStorage.getItem("paymentDetails"));

        if (paymentDetails) {
          try {
            const { amount, currency, numbers, subtotal, vat, tailorPrice } = paymentDetails;
            const total = parseFloat(amount);
            const subtotalNum = parseFloat(subtotal);
            const tailorPriceNumber = parseFloat(tailorPrice);

            const paymentResponse = await fetch("https://back.faithlandline.co.uk/api/payment", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                amount: total.toFixed(2),
                currency,
                numbers,
                subtotal: subtotalNum.toFixed(2),
                vat,
                tailorPrice: tailorPriceNumber.toFixed(2),
                user_id: data.user.id,
              }),
            });

            const paymentData = await paymentResponse.json();

            if (paymentResponse.ok) {
              const { sessionId } = paymentData;
              const stripe = await stripePromise;
              await stripe.redirectToCheckout({ sessionId });
            } else {
              toast.error("Error processing payment. Please try again.");
            }
          } catch (error) {
            toast.error("Error during payment processing. Please try again later.");
          }
        } else {
          navigate("/control-panel");
        }
      } else {
        toast.error(data.message || "Login failed");
      }
    } catch (error) {
      toast.error("Error logging in. Please try again later.");
    }
  };

  return (
    <div>
      <div className="breatcome-area align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breatcome-content">
                <div className="breatcome-title">
                  <h1>Login</h1>
                </div>
                <div className="breatcome-text">
                  <ul>
                    <li>
                      <Link to="/">
                        Home <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <span>Login</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-area mt-12">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="contact-section">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="contact-title">
                      <h2>Login to your account</h2>
                    </div>
                    <div className="control-contact-form">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 mb-4">
                            <div className="input-box">
                              <label>Email</label>
                              <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 mb-4">
                            <div className="input-box">
                              <label>Password</label>
                              <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="input-button">
                              <button type="submit">Login</button>
                            </div>
                          </div>
                          <div className="col-md-8">
                            <p>
                              Don't Have Account?{" "}
                              <Link to="/register">Register</Link>
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p>
                              <Link to="/forget-password">Forgot Password?</Link>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
