import React from "react";
import Orderlist from "../../component/admin/OrderList";

const OrderlistPage = () => {
  return (
    <div>
      <Orderlist />
    </div>
  );
};

export default OrderlistPage;
