import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Sidebar from "../Sidebar";

const AlreadyPurchased = () => {
  const [numbers, setNumbers] = useState([]);
  const userId = localStorage.getItem("user_id"); // Retrieve the user ID from localStorage

  useEffect(() => {
    const fetchNumbers = async () => {
      try {
        const response = await fetch(
          `https://back.faithlandline.co.uk/api/numbers/user/${userId}`
        );
        const data = await response.json();
        setNumbers(data);
      } catch (error) {
        console.error("Error fetching numbers:", error);
      }
    };

    fetchNumbers();
  }, [userId]);

  return (
    <div>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />

        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12 col-md-10 mx-auto">
              <div className="card mt-4 shadow-lg">
                <div className="card-header p-3 bg-gradient-primary text-white">
                  <h5 className="mb-0 text-white">Purchased Numbers</h5>
                  <p className="text-sm pt-2">
                    Below you will find a list of the numbers associated with
                    your account, along with any references you have given to
                    the numbers. To make a change to one of the numbers below,
                    simply select the number you wish to update. If you would
                    like to make outbound calls and display your virtual
                    telephone number, find out more about Tamar VoIP{" "}
                    <a
                      href="https://getbootstrap.com/docs/5.0/components/toasts/"
                      target="_blank"
                      className="text-warning fw-bold"
                    >
                      here
                    </a>
                    .
                  </p>
                </div>

                <div className="card-body p-4">
                  <h6 className="text-uppercase text-muted font-weight-bold">
                    Number Translation Service ({numbers.length} number{numbers.length > 1 ? 's' : ''})
                  </h6>

                  {numbers.map((number) => (
                    <div
                      key={number._id}
                      className="translation-service mt-3 p-3 rounded bg-light"
                    >
                      <div className="d-flex align-items-center">
                        <span className="icon me-3">
                          <i className="fas fa-phone-alt"></i>
                        </span>
                        <div>
                          <h5 className="mb-1">{number.number}</h5>
                          <p className="text-sm text-muted">
                            Diverted to{" "}
                            <strong>{number.destination || "No destination set"}</strong>
                          </p>
                          <p className="text-sm text-muted">
                            Tariff: <strong>{number.tariffPrice}</strong>, Price: <strong>${number.price}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </main>
    </div>
  );
};

export default AlreadyPurchased;
