import React from "react";
import PaymentPage from "../../component/ControlPanel/PaymentPage";
// import Payment from '../../../../../tamarco-api/models/Payment'

const Payment = () => {
  return (
    <div>
      <PaymentPage />
    </div>
  );
};

export default Payment;
