import React from "react";
import WhatsappBuisness from "../../component/ControlPanel/WhatsappBuisness";

const WhatsappBuisnessPage = () => {
  return (
    <div>
      <WhatsappBuisness />
    </div>
  );
};

export default WhatsappBuisnessPage;
