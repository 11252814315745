import React from "react";
import Numbers0800 from "../../component/ControlPanel/Numbers0800";

const Numbers0800Page = () => {
  return (
    <div>
      <Numbers0800 />
    </div>
  );
};

export default Numbers0800Page;
