import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import {
  Form,
  Button,
  Card,
  Row,
  Col,
  ListGroupItem,
  ListGroup,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify"; // Toast for notifications

const HolidaySettings = () => {
  const [showEditCard, setShowEditCard] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState("");
  const [holidayData, setHolidayData] = useState([]);
  const [showCreateCard, setShowCreateCard] = useState(false);
  const [holidayName, setHolidayName] = useState("");
  const [holidayDetails, setHolidayDetails] = useState({ dates: [] });
  // State for form inputs
  const [description, setDescription] = useState("");
  const [dates, setDates] = useState([]); // Array of date objects
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [id, setId] = useState("");
  const [descriptionForDate, setDescriptionForDate] = useState("");

  // Function to handle adding new dates
  const addNewDate = () => {
    if (fromDate && toDate && descriptionForDate) {
      setDates({
        ...dates,
        [fromDate]: descriptionForDate,
      });
      setFromDate("");
      setToDate("");
      setDescriptionForDate("");
    }
  };

  // Dummy API data (simulated)
  const fetchHolidayData = async () => {
    const user_id = localStorage.getItem("user_id");

    try {
      const response = await axios.get(
        `https://back.faithlandline.co.uk/api/holidays/${user_id}`
      );
      setHolidayData(response.data);
    } catch (error) {
      console.error("Error fetching holiday data:", error);
      toast.error("An error occurred while fetching holiday data.");
    }
  };

  // Function to handle the form submission
  const handleSaveChanges = async () => {
    try {
      const response = await axios.post(
        "https://back.faithlandline.co.uk/api/holiday/update",
        {
          id,
          description,
          days: dates,
          huntid:"0",
        }
      );

      if (response.data.Response === "200") {
        console.log("Holiday group updated.");
        // Handle success (e.g., show a success message)
      } else {
        console.log("Failed to update holiday group.");
        // Handle error (e.g., show an error message)
      }
    } catch (error) {
      console.error("Error updating holiday group:", error);
      // Handle error (e.g., show an error message)
    }
  };

  useEffect(() => {
    // Simulate API call
    fetchHolidayData();
  }, []);

  const handleCreateNew = () => {
    setShowCreateCard(true);
  };

  // Function to handle form submission
  const handleCreateHoliday = async (e) => {
    e.preventDefault();

    if (!holidayName) {
      toast.error("Please enter a holiday name.");
      return;
    }

    const user_id = localStorage.getItem("user_id");

    try {
      const response = await axios.post(
        "https://back.faithlandline.co.uk/api/holiday/new",
        {
          description: holidayName,
          user_id: user_id, // Replace with actual user ID from context or state
        }
      );

      if (response.status === 200) {
        toast.success("Holiday group created successfully!");
        setHolidayName(""); // Clear the form
        setShowCreateCard(false); // Hide the form after creation
        // Optionally fetch updated holiday data here
      } else {
        toast.error("Failed to create holiday group.");
      }
    } catch (error) {
      console.error("Error creating holiday group:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleViewEdit = (holiday) => {
    console.log(holiday.holiday);

    setSelectedHoliday(holiday.holiday.description);
    setId(holiday.holiday.id);
    setHolidayDetails(holiday.holiday);
    setShowCreateCard(false);
    setShowEditCard(true);
  };

  return (
    <div>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />
        <div className="container-fluid py-4">
          <Row className="justify-content-center">
            <Col lg={12}>
              <Card className="shadow-sm">
                <Card.Header>
                  <h5>Select an existing Holiday setting or create new</h5>
                </Card.Header>
                <Card.Body>
                  <p>
                    The holiday settings section of the portal is where your
                    holiday settings are managed and created. Once created, the
                    holiday settings then need to be applied to the individual
                    telephone numbers.
                  </p>

                  <Form.Group controlId="holidaySelect">
                    <Form.Label>Select from the list</Form.Label>
                    <Form.Control
                      as="select"
                      disabled={!holidayData.length}
                      value={selectedHoliday}
                      onChange={(e) =>
                        handleViewEdit(
                          holidayData.find(
                            (holiday) => holiday.holiday.id === e.target.value
                          )
                        )
                      }
                    >
                      <option>Select from the list</option>
                      {holidayData.length > 0
                        ? holidayData.map((holiday) => (
                            <option
                              key={holiday.holiday.id}
                              value={holiday.holiday.id}
                            >
                              {holiday.holiday.description}
                            </option>
                          ))
                        : "No data available"}
                    </Form.Control>
                  </Form.Group>

                  <div className="d-flex justify-content-end mt-4">
                    <Button
                      variant="secondary"
                      className="me-2"
                      onClick={handleViewEdit}
                    >
                      View/Edit
                    </Button>
                    <Button variant="primary" onClick={handleCreateNew}>
                      Create New
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Create New Holiday Card */}
          {showCreateCard && (
            <Row className="mt-4 justify-content-center">
              <Col lg={8}>
                <Card className="shadow-sm">
                  <Card.Header>
                    <h5>Create a New Holiday</h5>
                  </Card.Header>
                  <Card.Body>
                    <p>
                      To create a new holiday profile, enter a name for the
                      holiday profile in the Description box and click Create.
                    </p>
                    <Form onSubmit={handleCreateHoliday}>
                      <Form.Group controlId="holidayName">
                        <Form.Label>Name for this holiday</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter holiday name"
                          value={holidayName}
                          onChange={(e) => setHolidayName(e.target.value)}
                        />
                      </Form.Group>
                      <div className="d-flex justify-content-end mt-3">
                        <Button variant="primary" type="submit">
                          Create
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {showEditCard && (
            <Row className="mt-4">
              <Col md={6}>
                <Card className="shadow-sm">
                  <Card.Header>
                    <h5>Description of This Group</h5>
                  </Card.Header>
                  <Card.Body>
                    <Form.Group controlId="editHolidayName">
                      <Form.Label>Holiday Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={selectedHoliday}
                        readOnly
                      />
                    </Form.Group>

                    <Form.Group controlId="editDescription" className="mt-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Form.Group>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={6}>
            <Card className="shadow-sm">
              <Card.Header>
                <h5>When it is a Holiday Day...</h5>
              </Card.Header>
              <Card.Body>
                <Form.Group controlId="addDateFrom">
                  <Form.Label>From</Form.Label>
                  <Form.Control
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="addDateTo" className="mt-3">
                  <Form.Label>To</Form.Label>
                  <Form.Control
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="addDescription" className="mt-3">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    value={descriptionForDate}
                    onChange={(e) => setDescriptionForDate(e.target.value)}
                  />
                </Form.Group>

                <div className="d-flex justify-content-end mt-3">
                  <Button variant="primary" onClick={addNewDate}>
                    Add Date
                  </Button>
                </div>

                <h5 className="mt-4">Your Current Holiday Dates</h5>
                <ListGroup className="list-group-flush bg-light p-3 rounded shadow-sm">
                  {Object.entries(dates).map(([date, desc], index) => (
                    <ListGroupItem key={index} className="d-flex justify-content-between align-items-center py-3">
                      <span>
                        <strong>{date}</strong> - {desc}
                      </span>
                    </ListGroupItem>
                  ))}
                </ListGroup>

                <div className="d-flex justify-content-end mt-4">
                  <Button variant="primary" onClick={handleSaveChanges}>
                    Save Changes
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
            </Row>
          )}
        </div>
      </main>
    </div>
  );
};

export default HolidaySettings;
