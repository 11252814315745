import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import download from 'downloadjs';

const generateInvoicePDF = async (invoice) => {
  // Create a new PDF document
  const pdfDoc = await PDFDocument.create();

  // Add a page to the document
  const page = pdfDoc.addPage([600, 700]);

  // Set up fonts
  const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
  const fontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
  const fontSize = 12;

  // Add company header
  page.drawText('Tamar Telecom', {
    x: 50,
    y: 650,
    size: 20,
    font: fontBold,
    color: rgb(0, 0.53, 0.71), // Custom color for the header
  });

  page.drawText('INVOICE', {
    x: 450,
    y: 650,
    size: 20,
    font: fontBold,
    color: rgb(0.95, 0.26, 0.21), // Another custom color for the title
  });

  // Draw lines to separate header
  page.drawLine({
    start: { x: 50, y: 640 },
    end: { x: 550, y: 640 },
    thickness: 2,
    color: rgb(0.65, 0.65, 0.65),
  });

  // Add invoice details
  const detailsY = 610;

  page.drawText(`Invoice ID: INV-${invoice._id.slice(-6).toUpperCase()}`, {
    x: 50,
    y: detailsY,
    size: fontSize,
    font: fontBold,
  });
  page.drawText(`Date: ${new Date(invoice.createdAt).toLocaleDateString()}`, {
    x: 50,
    y: detailsY - 20,
    size: fontSize,
    font,
  });
  page.drawText(`Due Date: ${new Date(invoice.createdAt).toLocaleDateString()}`, {
    x: 50,
    y: detailsY - 40,
    size: fontSize,
    font,
  });

  page.drawText(`Bill To:`, {
    x: 50,
    y: detailsY - 80,
    size: fontSize,
    font: fontBold,
  });
  page.drawText(`Number: ${invoice.number}`, {
    x: 50,
    y: detailsY - 100,
    size: fontSize,
    font,
  });
  page.drawText(`Tariff: ${invoice.tariff}`, {
    x: 50,
    y: detailsY - 120,
    size: fontSize,
    font,
  });
  page.drawText(`Destination: ${invoice.destination}`, {
    x: 50,
    y: detailsY - 140,
    size: fontSize,
    font,
  });

  // Draw table headers
  const tableY = detailsY - 180;

  page.drawText('DESCRIPTION', {
    x: 50,
    y: tableY,
    size: fontSize,
    font: fontBold,
  });
  page.drawText('UNIT PRICE', {
    x: 250,
    y: tableY,
    size: fontSize,
    font: fontBold,
  });
  page.drawText('AMOUNT', {
    x: 450,
    y: tableY,
    size: fontSize,
    font: fontBold,
  });

  // Draw table content
  const itemY = tableY - 20;

  page.drawText(`Service Charge`, {
    x: 50,
    y: itemY,
    size: fontSize,
    font,
  });
  page.drawText(`$${invoice.amount.toFixed(2)}`, {
    x: 250,
    y: itemY,
    size: fontSize,
    font,
  });
  page.drawText(`$${invoice.amount.toFixed(2)}`, {
    x: 450,
    y: itemY,
    size: fontSize,
    font,
  });

  // Draw total amount
  const totalY = itemY - 40;

  page.drawLine({
    start: { x: 50, y: totalY + 30 },
    end: { x: 550, y: totalY + 30 },
    thickness: 2,
    color: rgb(0.65, 0.65, 0.65),
  });

  page.drawText('TOTAL', {
    x: 50,
    y: totalY,
    size: fontSize,
    font: fontBold,
  });
  page.drawText(`$${invoice.amount.toFixed(2)}`, {
    x: 450,
    y: totalY,
    size: fontSize,
    fontBold,
  });

  // Add footer
  page.drawText('Thank you for buying virtual number!', {
    x: 50,
    y: 50,
    size: fontSize,
    font: fontBold,
    color: rgb(0, 0.53, 0.71),
  });

  // Serialize the PDFDocument to bytes
  const pdfBytes = await pdfDoc.save();

  // Trigger the download of the PDF
  download(pdfBytes, `invoice_${invoice._id.slice(-6).toUpperCase()}.pdf`, 'application/pdf');
};

export default generateInvoicePDF;
