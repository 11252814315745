import React from "react";
import MainComponent from "./MainComponent";

const PurchasedNumber = () => {
  return (
    <div>
      <MainComponent />
    </div>
  );
};

export default PurchasedNumber;
