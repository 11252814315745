import React from "react";
import Billing from "../../component/ControlPanel/Billing";

const BillingPage = () => {
  return (
    <div>
      <Billing />
    </div>
  );
};

export default BillingPage;
