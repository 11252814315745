import React from "react";
import ControlPanel from "../component/ControlPanel";

const ControlPanelPage = () => {
  return (
    <div>
      <ControlPanel />
    </div>
  );
};

export default ControlPanelPage;
