import React from "react";
import ForgetPassword from "../component/ForgetPassword";

const ForgetPasswordPage = () => {
  return (
    <div>
      <ForgetPassword />
    </div>
  );
};

export default ForgetPasswordPage;
