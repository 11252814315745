import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer";
import axios from "axios";
import { Modal, Button } from "react-bootstrap"; // Bootstrap modal components

const Profile = () => {
  const [userData, setUserData] = useState(null); // Holds user data from API
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [updatedUserData, setUpdatedUserData] = useState({}); // State to handle form data for updates

  // Fetch user data when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      const userId = localStorage.getItem("user_id"); // Get user ID from localStorage
      try {
        const response = await axios.get(
          `https://back.faithlandline.co.uk/api/auth/user/${userId}`
        );
        setUserData(response.data.user);
        setUpdatedUserData(response.data.user); // Set initial values for the modal form
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  // Show modal when clicking the edit button
  const handleShowModal = () => setShowModal(true);
  // Close modal without saving
  const handleCloseModal = () => setShowModal(false);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Save changes and update profile data
  const handleSaveChanges = async () => {
    const userId = localStorage.getItem("user_id");
    try {
      await axios.patch(
        `https://back.faithlandline.co.uk/api/auth/user/${userId}`,
        updatedUserData
      );
      setUserData(updatedUserData); // Update state with new data
      handleCloseModal(); // Close modal after saving
    } catch (error) {
      console.error("Error updating user data:", error);
    }
  };

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />
        <div className="container-fluid px-2 px-md-4">
          <div
            className="page-header min-height-300 border-radius-xl mt-4"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1531512073830-ba890ca4eba2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1920&amp;q=80')",
            }}
          >
            <span className="mask bg-gradient-primary opacity-6"></span>
          </div>
          <div className="card card-body mx-3 mx-md-4 mt-n6">
            <div className="row gx-4 mb-2">
              <div className="col-auto">
                <div className="avatar avatar-xl position-relative">
                  <img
                    src={
                      userData.profileImageUrl || "../assets/img/bruce-mars.jpg"
                    }
                    alt="profile_image"
                    className="w-100 border-radius-lg shadow-sm"
                  />
                </div>
              </div>
              <div className="col-auto my-auto">
                <div className="h-100">
                  <h5 className="mb-1">{userData.username}</h5>
                  <p className="mb-0 font-weight-normal text-sm">
                    {userData.fullName}
                  </p>
                  <p className="mb-0 font-weight-normal text-sm">
                    {userData.email}
                  </p>
                </div>
              </div>
              <div className="col-auto text-end">
                <i
                  className="fas fa-user-edit text-secondary text-sm"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Edit Profile"
                  onClick={handleShowModal} // Open modal when clicked
                  style={{ cursor: "pointer" }}
                ></i>
              </div>
            </div>

            {/* Additional profile details */}
            <div className="row">
              <div className="col-lg-6">
                <ul className="list-group">
                 
                  <li className="list-group-item border-0 ps-0 text-sm">
                    <strong className="text-dark">Username:</strong> &nbsp;{" "}
                    {userData.username}
                  </li>
                  <li className="list-group-item border-0 ps-0 text-sm">
                    <strong className="text-dark">Email:</strong> &nbsp;{" "}
                    {userData.email}
                  </li>
                  {/* Add more details if needed */}
                </ul>
              </div>
            </div>

            {/* Modal for editing profile */}
            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Edit Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form>
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="username"
                      name="username"
                      value={updatedUserData.username || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="fullName" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      name="fullName"
                      value={updatedUserData.fullName || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      name="email"
                      value={updatedUserData.email || ""}
                      onChange={handleInputChange}
                    />
                  </div>
                  {/* Add more fields as needed */}
                </form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button variant="primary" onClick={handleSaveChanges}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default Profile;
