import React from "react";
import VirtualSwitchBoard from "../../component/ControlPanel/VirtualSwitchBoard";

const VirtualSwitchBoardPage = () => {
  return (
    <div>
      <VirtualSwitchBoard />
    </div>
  );
};

export default VirtualSwitchBoardPage;
