import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Modal from "react-modal";
import Pagination from "../../../examples/Pagination";
import ChoosePriceSection from "../../../examples/ChoosePrice";
import { loadStripe } from "@stripe/stripe-js";

// Replace with your actual Stripe public key
const stripePromise = loadStripe(
  "pk_live_51NIuvRELKdOM3HNefMdJ7x2SG1fQtz7NXJElWU9dHFaulGE9Smr4cDNH4r1JOF1hd0xllUOtZJRBmvEN9M0x5QjF00RKHdARuz"
);
const mapTariffToPlan = (tariffData) => {
  if (!tariffData || !tariffData.TF1 || !tariffData.TF1[0]) {
    return [];
  }

  return [
    {
      name: "Basic",
      value: "TF1",
      price: `${tariffData.TF1[0].Price}`,
      features: [
        `Divert To Landlines & Mobiles`,
        `${tariffData.TF1[0].inclusive} Inclusive Diversion Mins`,
        `${tariffData.TF1[0]["PPM Landline"]} ppm to landlines after inclusive`,
        "1 Month Rolling Contract",
        "Free Setup & Connection",
        "No Hidden Costs",
      ],
    },
    {
      name: "Growing Business",
      value: "TF2",
      price: `${tariffData.TF2?.[0]?.Price || "N/A"}`,
      features: [
        `Divert To Landlines & Mobiles`,
        `${tariffData.TF2?.[0]?.inclusive || "N/A"} Inclusive Diversion Mins`,
        `${
          tariffData.TF2?.[0]?.["PPM Landline"] || "N/A"
        } ppm to landlines after inclusive`,
        "1 Month Rolling Contract",
        "Free Setup & Connection",
        "No Hidden Costs",
      ],
    },
    {
      name: "Enterprise",
      value: "TF3",
      price: `${tariffData.TF3?.[0]?.Price || "N/A"}`,
      features: [
        `2000 Inbound Mins To Mobile App`,
        `${tariffData.TF3?.[0]?.inclusive || "N/A"} Diversion Mins`,
        `${
          tariffData.TF3?.[0]?.["PPM Landline"] || "N/A"
        } ppm to landlines after inclusive`,
        "1 Month Rolling Contract",
        "Free Setup & Connection",
        "No Hidden Costs",
        "PLUS These Premium Features",
      ],
      premiumFeatures: [
        "Dedicated VoIP App With:",
        "Outbound Calling",
        "Call Transfer",
        "On Hold Music",
        "Conference Calling",
        "On Demand Audio Saving",
        "Call Whisper",
        "Virtual Switchboard",
        "No Hidden Costs",
      ],
    },
  ];
};

const Numbers0800 = () => {
  const navigate = useNavigate();
  const [callType, setCallType] = useState("Receive");
  const [tailor, setTailor] = useState("");
  const [tariffs, setTariffs] = useState({});
  const [whisperPrice, setWhisperPrice] = useState("");
  const [vsbPrice, setVsbPrice] = useState("");
  const [partialNumbers, setPartialNumbers] = useState({});
  const [category, setCategory] = useState("ALL");
  const [tailorPrice, setTailorPrice] = useState(0);
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentNumber, setCurrentNumber] = useState("");
  const [currentNumberPrice, setCurrentNumberPrice] = useState("");
  const [destination, setDestination] = useState("");
  const [showButton, setShowButton] = useState(true);
  const [ButtonDisabled, setButtonDisabled] = useState(false);
  const [tariffPrice, setTariffPrice] = useState(0);
  const [tariff, setTariff] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numbersPerPage] = useState(84);
  const [currentStep, setCurrentStep] = useState(1);
  // Function to open the modal with the selected number
  const openModal = (number, price) => {
    setSelectedNumbers({ number, price });
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePlanClick = (type) => {
    setCallType(type);
    setCurrentStep(2);
  };

  const handleBackClick = () => {
    setCurrentStep(1);
    setCallType("");
  };

  useEffect(() => {
    const fetchAllPrices = async () => {
      try {
        const [tariffResponse, whisperResponse, vsbResponse] =
          await Promise.all([
            axios.get(`https://back.faithlandline.co.uk/api/numbers/tariffs/prices`),
            axios.get(`https://back.faithlandline.co.uk/api/numbers/whisper/prices`),
            axios.get(`https://back.faithlandline.co.uk/api/numbers/vsb/prices`),
          ]);
        const whisperPriceString = whisperResponse.data.price;
        const vsbPriceString = vsbResponse.data.price;
        const parsedWhisperPrice = parseFloat(
          whisperPriceString.replace("£", "")
        );
        const parsedVsbPrice = parseFloat(vsbPriceString.replace("£", ""));

        setTariffs(tariffResponse.data);
        setWhisperPrice(parsedWhisperPrice);
        console.log(whisperResponse.data.price);
        setVsbPrice(parsedVsbPrice);
      } catch (error) {
        console.error("Error fetching prices:", error);
      }
    };

    fetchAllPrices();
  }, []);

  const plans = mapTariffToPlan(tariffs);

  const fetchPartialNumbers = async () => {
    try {
      const response = await axios.get(
        `https://back.faithlandline.co.uk/api/numbers/partial/0800`
      );
      setPartialNumbers(response.data);
      setShowButton(false);
    } catch (error) {
      console.error("Error fetching partial numbers:", error);
    }
  };

  const handleAddNumber = (number, price) => {
    setCurrentNumber(number);
    setCurrentNumberPrice(price);
    setIsModalOpen(true);
    setTailor("");
  };

  const handleConfirmNumber = () => {
    setSelectedNumbers([
      ...selectedNumbers,
      {
        number: currentNumber,
        price: currentNumberPrice,
        destination,
        tariff: tariff,
        tariffPrice: tariffPrice,
        tailorPrice: tailorPrice,
        tailor:
          tailorPrice === whisperPrice
            ? "whisper"
            : tailorPrice === vsbPrice
            ? "vsb"
            : "",
      },
    ]);
    setIsModalOpen(false);
    setDestination("");
    setCurrentNumber(null);
    setPartialNumbers({});
    setShowButton(true);
  };

  const calculateTotalPrice = () => {
    return selectedNumbers.reduce((total, number) => {
      const numberPrice = parseFloat(number.price) || 0;
      const tariffPrice = parseFloat(number.tariff) || 0;
      const tailorPrice = parseFloat(number.tailorPrice) || 0;
      return total + numberPrice + tariffPrice + tailorPrice;
    }, 0);
  };

  // const handleProceedToPayment = () => {
  //   const subtotal = calculateTotalPrice();
  //   const vat = parseFloat((subtotal * 0.2).toFixed(2));
  //   const total = subtotal + vat + parseFloat(tailorPrice);

  //   navigate("/admin/payment", {
  //     state: {
  //       selectedNumbers,
  //       tailorPrice,
  //       subtotal,
  //       vat,
  //       total: total.toFixed(2),
  //     },
  //   });
  // };

  const filteredNumbers = Object.entries(partialNumbers).filter(
    ([number, price]) => {
      if (category === "ALL") return true;
      if (category === "Bronze" && price === "0") return true;
      if (category === "Silver" && price === "50") return true;
      if (category === "Gold" && price === "200") return true;
      if (category === "Platinum" && price === "1300") return true;
      return false;
    }
  );

  const TariffHandler = (e, tariffdata, price, index) => {
    e.preventDefault();
    if (tariff === tariffdata) {
      setTariff("");
      setTariffPrice(0);
    } else {
      setTariff(tariffdata);
      setTariffPrice(price);
    }
    if (index === 0 || index === 1) {
      setTailor("");
      setTailorPrice();
    }
  };
  const TailorHandler = (e, tailordata, price, index) => {
    e.preventDefault();
    if (tailor === tailordata) {
      setTailor("");
      setTailorPrice();
    } else {
      setTailor(tailordata);
      setTailorPrice(price);
    }
  };

  const indexOfLastNumber = currentPage * numbersPerPage;
  const indexOfFirstNumber = indexOfLastNumber - numbersPerPage;
  const currentNumbers = filteredNumbers.slice(
    indexOfFirstNumber,
    indexOfLastNumber
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  function getCategoryBadge(price) {
    if (price >= 1300) {
      return "platinum";
    } else if (price >= 200) {
      return "gold";
    } else if (price >= 50) {
      return "silver";
    } else {
      return "bronze";
    }
  }

  const handleProceedToPayment = async () => {
    const subtotal = calculateTotalPrice();
    const vat = parseFloat((subtotal * 0.2).toFixed(2));
    const total = subtotal + vat + parseFloat(tailorPrice);

    try {
      // Save payment details in localStorage
      localStorage.setItem(
        "paymentDetails",
        JSON.stringify({
          amount: total.toFixed(2),
          currency: "usd", // or any currency you're using
          numbers: selectedNumbers,
          subtotal: subtotal.toFixed(2),
          vat,
          tailorPrice: tailorPrice.toFixed(2),
        })
      );

      // Proceed with payment processing
      const response = await fetch("https://back.faithlandline.co.uk/api/payment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: total.toFixed(2),
          currency: "usd",
          numbers: selectedNumbers,
          subtotal: subtotal.toFixed(2),
          vat,
          tailorPrice: tailorPrice.toFixed(2),
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Assuming you're getting a sessionId to redirect the user
        const { sessionId } = data;
        const stripe = await stripePromise;
        await stripe.redirectToCheckout({ sessionId });
      } else {
        console.error("Error processing payment:", data.error);
        // Handle the error (e.g., show a notification to the user)
      }
    } catch (error) {
      console.error("Error occurred during payment processing:", error.message);
      // Handle the error (e.g., show a notification to the user)
    }
  };

  return (
    <div>
      <Sidebar />

      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />
        <div className="main-page">
          {currentStep === 1 && (
            <section className="main-page__hero py-5">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6">
                    <div className="main-page__hero-content">
                      <h1 className="display-4 font-weight-bold text-primary mb-4">
                        0800 Freephone Numbers
                      </h1>
                      <p className="text-secondary h4">
                        Free To Call From Mobile & Landlines
                      </p>
                      <p className="border-start border-3 ps-3 my-4 text-muted">
                        0800 numbers are now free to call from both UK landlines
                        and mobiles. Most competitors will charge a surcharge
                        when someone calls your 0800 number from a mobile. As a
                        Tamar Telecommunications customer, this won’t affect
                        you, as we’ve decided to absorb this cost!
                      </p>
                      <p className="text-muted">
                        0800 Freephone numbers are free to call from UK
                        landlines and from UK mobiles. They act as a great way
                        to encourage sales enquiries, giving your customer no
                        reason not to pick up the phone. 0800 Numbers also offer
                        great customer service by allowing your customers to
                        call you for free.
                      </p>
                      <p className="text-muted">
                        Our 0800 Freephone numbers are provided with a FREE
                        Control Panel & App to manage your number. You will also
                        get free advanced services, like Timed Call Diverts and
                        Voicemail. This enables you to change where your calls
                        are diverted to instantly 24/7. You can tailor your
                        number to suit your business needs.
                      </p>
                      <p className="text-muted">
                        To order your new 0800 freephone number, simply select a
                        tariff from the table below, then pick your new number –
                        it’s that simple!
                      </p>
                      <a href="#chooseNumber">
                        <button className="btn btn-primary btn-lg mt-4">
                          Choose Your Number
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-6 text-center">
                    <img
                      src="https://www.tamartelecommunications.co.uk/wp-content/themes/tamar/img/hero-lady.webp"
                      alt="Hero"
                      className="img-fluid rounded shadow-lg"
                    />
                  </div>
                </div>
              </div>
            </section>
          )}

          {currentStep === 1 && (
            <section id="chooseNumber" className="main-page__numbers py-5">
              <div className="container">
                <h2 className="text-center text-gradient bg-gradient-to-t from-pink-600 to-red-500 mb-5">
                  Step 1: What Type Of Service Do You Need?
                </h2>
                <div className="main-page__numbers-options row justify-content-around">
                  <div className="main-page__number-option col-lg-5 card shadow-lg p-4 mb-4">
                    <h3 className="text-center text-primary mb-3">
                      Receive Calls
                    </h3>
                    <p className="text-lg text-center">
                      Receive Calls with Our Classic Virtual Numbers
                    </p>
                    <p className="text-center font-weight-bold text-muted my-4">
                      Professional Business Phone Numbers. Simple, Affordable,
                      Effective
                    </p>
                    <ul className="list-unstyled px-4">
                      <li className="dropdownItem mb-3 d-flex align-items-start">
                        <span className="dot me-2"></span>
                        <div className="itemContent">
                          <p className="itemDescription">
                            Mobile landline numbers
                          </p>
                        </div>
                      </li>
                      <li className="dropdownItem mb-3 d-flex align-items-start">
                        <span className="dot me-2"></span>
                        <div className="itemContent">
                          <p className="itemDescription">
                            Free to call from landlines & mobiles
                          </p>
                        </div>
                      </li>
                      <li className="dropdownItem d-flex align-items-start">
                        <span className="dot me-2"></span>
                        <div className="itemContent">
                          <p className="itemDescription">
                            Numbers for a nationwide presence
                          </p>
                        </div>
                      </li>
                    </ul>
                    <a href="#choosePrice">
                      <button
                        onClick={() => handlePlanClick("Receive")}
                        className={`btn btn-lg w-100 mt-4 ${
                          callType === "Receive"
                            ? "btn-warning"
                            : "btn-outline-primary"
                        }`}
                      >
                        {callType === "Receive"
                          ? "Selected"
                          : "Choose Your Number"}
                      </button>
                    </a>
                  </div>

                  <div className="main-page__number-option col-lg-5 card shadow-lg p-4">
                    <h3 className="text-center text-primary mb-3">
                      Make & Receive Calls
                    </h3>
                    <p className="text-lg text-center">
                      Our Complete VoIP System
                    </p>
                    <p className="text-center font-weight-bold text-muted my-4">
                      Professional Business Phone Numbers. Simple, Affordable,
                      Effective
                    </p>
                    <ul className="list-unstyled px-4">
                      <li className="dropdownItem mb-3 d-flex align-items-start">
                        <span className="dot me-2"></span>
                        <div className="itemContent">
                          <p className="itemDescription">
                            Mobile landline numbers
                          </p>
                        </div>
                      </li>
                      <li className="dropdownItem mb-3 d-flex align-items-start">
                        <span className="dot me-2"></span>
                        <div className="itemContent">
                          <p className="itemDescription">
                            Free to call from landlines & mobiles
                          </p>
                        </div>
                      </li>
                      <li className="dropdownItem d-flex align-items-start">
                        <span className="dot me-2"></span>
                        <div className="itemContent">
                          <p className="itemDescription">
                            Numbers for a nationwide presence
                          </p>
                        </div>
                      </li>
                    </ul>
                    <a href="#choosePrice">
                      <button
                        onClick={() => handlePlanClick("Make")}
                        className={`btn btn-lg w-100 mt-4 ${
                          callType === "Make"
                            ? "btn-warning"
                            : "btn-outline-primary"
                        }`}
                      >
                        {callType === "Make"
                          ? "Selected"
                          : "Choose Your Number"}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )}

          {currentStep === 2 && (
            <div>
              <div className="container">
                <button
                  onClick={handleBackClick}
                  className="btn btn-secondary mb-4"
                >
                  Back
                </button>
              </div>
              <ChoosePriceSection
                plans={plans}
                tariffPrice={tariffPrice}
                fetchPartialNumbers={fetchPartialNumbers}
                TariffHandler={TariffHandler}
                setButtonDisabled={setButtonDisabled}
                ButtonDisabled={ButtonDisabled}
                setTailor={setTailor}
              />
            </div>
          )}
          {currentStep === 2 && (
            <>
              <section id="tailor" className="main-page__numbers py-5 bg-light">
                <div className="container">
                  <h2 className="text-center text-transparent bg-clip-text bg-gradient-to-t from-[#c6117d] to-[#e50471] mb-4">
                    Step 3: Tailor Your Number
                  </h2>
                  <div className="row justify-content-around">
                    <div className="col-lg-5 col-md-6 mb-4">
                      <div className="card h-100 shadow-sm">
                        <div className="card-body text-center">
                          <h3 className="card-title">Call Whisper</h3>
                          <p className="text-muted">"Incoming Business Call"</p>
                          <p className="font-weight-bold text-secondary py-3">
                            What's Included
                          </p>
                          <ul className="list-unstyled text-left">
                            <li className="mb-2">
                              <i className="bi bi-check-circle-fill text-success"></i>{" "}
                              Call Whisper
                            </li>
                            <li className="mb-2">
                              <i className="bi bi-check-circle-fill text-success"></i>{" "}
                              Caller Announcement
                            </li>
                            <li>
                              <i className="bi bi-check-circle-fill text-success"></i>{" "}
                              Press 1 To Accept A Call
                            </li>
                          </ul>
                          <p className="font-weight-bold text-secondary py-3">
                            {whisperPrice} Per Month + VAT
                          </p>
                          <button
                            onClick={(e) =>
                              TailorHandler(e, "Whisper", whisperPrice, 1)
                            }
                            className={`btn btn-primary ${
                              tailor === "Whisper" || tailor === "Switchboard"
                                ? "disabled"
                                : ""
                            }`}
                            disabled={ButtonDisabled}
                          >
                            {tailor === "Whisper" ? (
                              <span>Selected</span>
                            ) : tailor === "Switchboard" ? (
                              <span>Included with Virtual Switchboard</span>
                            ) : tariff === "TF3" ? (
                              <span>Included With Unlimited</span>
                            ) : (
                              <span>Add to your number</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 mb-4">
                      <div className="card h-100 shadow-sm">
                        <div className="card-body text-center">
                          <h3 className="card-title">Virtual Switchboard</h3>
                          <p className="text-muted">
                            "Press 1 For Sales, 2 For Accounts..."
                          </p>
                          <p className="font-weight-bold text-secondary py-3">
                            What's Included
                          </p>
                          <ul className="list-unstyled text-left">
                            <li className="mb-2">
                              <i className="bi bi-check-circle-fill text-success"></i>{" "}
                              Virtual Switchboard
                            </li>
                            <li className="mb-2">
                              <i className="bi bi-check-circle-fill text-success"></i>{" "}
                              Up To 10 Menu Options
                            </li>
                            <li>
                              <i className="bi bi-check-circle-fill text-success"></i>{" "}
                              Free Professional Greeting
                            </li>
                          </ul>
                          <p className="font-weight-bold text-secondary py-3">
                            {vsbPrice} Per Month + VAT
                          </p>
                          <button
                            onClick={(e) => {
                              TailorHandler(e, "Switchboard", vsbPrice, 1);
                              console.log(tailorPrice);
                            }}
                            className={`btn btn-primary ${
                              tailor === "Switchboard" ? "disabled" : ""
                            }`}
                            disabled={ButtonDisabled}
                          >
                            {tailor === "Switchboard"
                              ? "Selected"
                              : "Add to your Number"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <div className="container-fluid py-4">
          {/* Filter Section */}
          <div className="row mb-4">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Select Category:</h5>
                <div className="btn-group">
                  <button
                    className={`btn ${
                      category === "ALL" ? "btn-primary" : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("ALL")}
                  >
                    All
                  </button>
                  <button
                    className={`btn ${
                      category === "Bronze"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Bronze")}
                  >
                    Bronze
                  </button>
                  <button
                    className={`btn ${
                      category === "Silver"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Silver")}
                  >
                    Silver
                  </button>
                  <button
                    className={`btn ${
                      category === "Gold"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Gold")}
                  >
                    Gold
                  </button>
                  <button
                    className={`btn ${
                      category === "Platinum"
                        ? "btn-primary"
                        : "btn-outline-primary"
                    }`}
                    onClick={() => setCategory("Platinum")}
                  >
                    Platinum
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Cards Section */}
          <div className="row">
            {currentNumbers.map(([number, price]) => (
              <div className="col-lg-4 col-md-6 mb-4" key={number}>
                <div
  className="card h-100 border-0 shadow-lg rounded-lg position-relative card-hover"
  style={{ overflow: 'hidden', transition: 'all 0.3s ease-in-out' }}
>
  {/* Card Content */}
  <div className="card-body d-flex justify-content-between align-items-center p-4">
    {/* Number Display */}
    <h5 className="card-title font-weight-bold mb-0">{number}</h5>

    {/* Category Badge */}
    <span
  className={`badge fs-6 px-3 py-2 ${
    getCategoryBadge(price) === "platinum"
      ? "bg-gradient-info"
      : getCategoryBadge(price) === "gold"
      ? "bg-gradient-warning"
      : getCategoryBadge(price) === "silver"
      ? "bg-gradient-success"
      : "bg-gradient-secondary"
  } custom-badge`}
>
  {getCategoryBadge(price)}
</span>

<style jsx>{`
  .custom-badge {
    font-size: 10px;  
  }
`}</style>

  </div>

  {/* Hover Elements (Price & Add Button) */}
  <div
    className="card-hover-content position-absolute d-flex flex-column align-items-center justify-content-center w-100 h-100"
    style={{
      top: '0',
      left: '0',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      opacity: '0',
      transition: 'opacity 0.3s ease-in-out',
      color: 'white',
    }}
  >
    {/* Price Display */}
    <div className="mb-3">
      <span className="badge bg-primary fs-5 px-3 py-2">£{price}</span>
    </div>

    {/* Add Button */}
    <button
      className="btn btn-light btn-sm w-75 py-2"
      onClick={() =>
        handleAddNumber(number, price, getCategoryBadge(price))
      }
      style={{color:"black"}}
    >
      Add
    </button>
  </div>

  {/* Hover Effect */}
  <style jsx>{`
    .card:hover .card-hover-content {
      opacity: 1;
    }
  `}</style>
</div>

              </div>
            ))}
          </div>

          {/* Pagination */}
          <Pagination
            itemsPerPage={numbersPerPage}
            totalItems={filteredNumbers.length}
            paginate={paginate}
            currentPage={currentPage}
          />

          {/* Selected Numbers Section */}
          {selectedNumbers.length > 0 && (
            <div className="my-5">
              <h3>Selected Numbers:</h3>
              <ul>
                {selectedNumbers.map(({ number, destination, price }) => (
                  <li key={number}>
                    {number} - Destination: {destination} - £{price}
                  </li>
                ))}
              </ul>
              <button
                className="mt-3 btn btn-info text-white py-2 px-4 rounded"
                onClick={handleProceedToPayment}
              >
                Proceed to Payment
              </button>
            </div>
          )}

          {/* Modal */}
          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setIsModalOpen(false)}
            ariaHideApp={false}
            contentLabel="Enter Destination"
            className="custom-modal-content"
            overlayClassName="custom-modal-overlay"
          >
            <h2 className="modal-title">
              Enter Destination for {currentNumber}
            </h2>
            <input
              type="text"
              value={destination}
              onChange={(e) => setDestination(e.target.value)}
              placeholder="Enter destination"
              className="form-control"
            />
            <div className="modal-buttons">
              <button className="btn btn-primary" onClick={handleConfirmNumber}>
                Confirm
              </button>
              <button
                className="btn btn-secondary"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </Modal>
        </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default Numbers0800;
