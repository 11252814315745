import React from 'react'
import HeroSection from './HeroSection'
import FrontNavbar from '../essentials/FrontNavbar'
import FrontFooter from '../essentials/FrontFooter'

const Login = () => {
  return (
    <div>
        <FrontNavbar/>
        <HeroSection/>
        <FrontFooter/>
    </div>
  )
}

export default Login