import React from "react";
import { Link } from "react-router-dom";

const FrontFooter = () => {
  return (
      <div class="footer-area mt-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6">
              <div class="single-widget">
                <div class="widge-content">
                  <div class="widge-thumb">
                    <img src="assets/images/footer-logo.png" alt="logo" />
                  </div>
                  <div class="widge-about-text">
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-widget upper">
                <div class="widge-content">
                  <div class="widget-title">
                    <h4>Popular Links</h4>
                  </div>
                  <div class="widge-link">
                    <ul>
                      <li>
                        <Link to="/home">Home</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-widget">
                <div class="widge-content">
                  <div class="widget-title">
                    <h4>Useful Links</h4>
                  </div>
                  <div class="widge-link">
                    <ul>
                      <li>
                        <Link to="/faqs">Faqs</Link>
                      </li>
                      <li>
                        <Link to="/terms-conditions">Term & Conditon</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6">
              <div class="single-widget">
                <div class="widge-content">
                  <div class="widget-title footer-title">
                    <h4>Feel Free to Contact Us! Say Hello</h4>
                  </div>
                  <div class="widget-address">
                    <div class="widget-inner d-flex">
                      <div class="widget-icon">
                        <i class="flaticon-mail"></i>
                      </div>
                      <div class="widget-social-information">
                        <h5>Email Us</h5>
                        <p>mail@gmail.com</p>
                      </div>
                    </div>
                    <div class="widget-inner d-flex">
                      <div class="widget-icon">
                        <i class="flaticon flaticon-phone-call"></i>
                      </div>
                      <div class="widget-social-information">
                        <h5>Phone Us</h5>
                        <p>+012 (345) 678 99</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="copyright-text">
                <p>© 2024 Site Name. All rights reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default FrontFooter;
