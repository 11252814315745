import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import Navbar from "../Navbar";
import Footer from "../Footer";
import axios from "axios";
import generateInvoicePDF from "./generateInvoicePDF";

const Billing = () => {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const userId = localStorage.getItem("user_id");

  useEffect(() => {
    axios
      .get(`https://back.faithlandline.co.uk/api/payment/invoices/${userId}`)
      .then((response) => {
        setInvoices(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }, [userId]);

  const handleShowDetails = (invoice) => {
    setSelectedInvoice(invoice);
    const modal = new window.bootstrap.Modal(document.getElementById('invoiceModal'));
    modal.show();
  };

  const handleDownloadInvoice = (invoice) => {
    console.log(invoice);
    
    generateInvoicePDF(invoice);
  };

  return (
    <div>
      <Sidebar />
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
        <Navbar />
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="card h-100">
                <div className="card-header pb-0 p-3">
                  <div className="row">
                    <div className="col-6 d-flex align-items-center">
                      <h6 className="mb-0">Invoices</h6>
                    </div>
                  </div>
                </div>
                <div className="card-body p-3 pb-0">
                  <ul className="list-group">
                    {invoices.map((invoice) => (
                      <li
                        key={invoice._id}
                        className="list-group-item border-0 d-flex justify-content-between ps-0 mb-2 border-radius-lg"
                      >
                        <div className="d-flex flex-column">
                          <h6 className="mb-1 text-dark font-weight-bold text-sm">
                            {new Date(invoice.createdAt).toLocaleDateString()}
                          </h6>
                          <span className="text-xs">#INV-{invoice._id.slice(-6).toUpperCase()}</span>
                        </div>
                        <div className="d-flex align-items-center text-sm">
                          ${invoice.amount}
                          <button
                            className="btn btn-link text-dark text-sm mb-0 px-0 ms-4"
                            onClick={() => handleShowDetails(invoice)}
                          >
                            <i className="material-icons text-lg position-relative me-1">
                              visibility
                            </i>
                            Details
                          </button>
                          <button class="btn btn-link text-dark text-sm mb-0 px-0 ms-4" onClick={() => handleDownloadInvoice(invoice)}>
                          <i class="material-icons text-lg position-relative me-1">picture_as_pdf</i> PDF
                      
                      </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </main>

      {/* Modal */}
      <div
        className="modal fade"
        id="invoiceModal"
        tabIndex="-1"
        aria-labelledby="invoiceModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="invoiceModalLabel">
                Invoice Details
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {selectedInvoice && (
                <div>
                  <p><strong>Number:</strong> {selectedInvoice.number}</p>
                  <p><strong>Tariff:</strong> {selectedInvoice.tariff}</p>
                  <p><strong>Destination:</strong> {selectedInvoice.destination}</p>
                  <p><strong>Amount:</strong> ${selectedInvoice.amount}</p>
                  <p><strong>Currency:</strong> {selectedInvoice.currency}</p>
                  <p><strong>Payment Status:</strong> {selectedInvoice.paymentId.status}</p>
                  <p><strong>Payment Created At:</strong> {new Date(selectedInvoice.paymentId.createdAt).toLocaleString()}</p>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Billing;
