import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const SuccessPage = () => {
  const { sessionId } = useParams();
  const [isPaymentProcessed, setIsPaymentProcessed] = useState(false); // State to track if payment is processed
  const paymentDetails = JSON.parse(localStorage.getItem("paymentDetails"));
  const navigate = useNavigate();

  useEffect(() => {
    // Ensure paymentDetails is available and payment has not been processed yet
    if (paymentDetails && !isPaymentProcessed) {
      const { amount, currency, numbers, subtotal, vat, tailorPrice } =
        paymentDetails;
      const user_id = localStorage.getItem("user_id");

      // Mark payment as processed
      setIsPaymentProcessed(true);

      axios
        .post("https://back.faithlandline.co.uk/api/payment/finalize-payment", {
          userId: user_id,
          paymentIntentId: sessionId,
          numbers,
          amount,
          currency,
          subtotal,
          vat,
          tailorPrice,
        })
        .then((response) => {
          console.log(response.data.message);
          localStorage.removeItem("paymentDetails");
          window.location.href = "/control-panel"; // Use navigate for SPA
        })
        .catch((error) => {
          console.error("Error finalizing payment:", error.message);
          // Handle error appropriately, e.g., show an error message to the user
        });
    }
  }, [paymentDetails, isPaymentProcessed, navigate, sessionId]); // Dependency array to handle changes

  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        Loading...
      </div>
    </div>
  );
};

export default SuccessPage;
