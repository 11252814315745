import React from "react";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <div>
      {/* <!--breatcome-area Start--> */}
      {/* <!--=================--> */}
      <div class="breatcome-area align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="breatcome-content">
                <div class="breatcome-title">
                  <h1>About Us</h1>
                </div>
                <div class="breatcome-text">
                  <ul>
                    <li>
                    <Link to="/">

                        Home <i class="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <span>About Us</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- breatcome-area end-->	 */}

      {/* <!--about-us-area-start--> */}
      <div class="about-us-area about">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="about-thumb wow fadeInLeft" data-wow-delay=".1s">
                <img
                  src="assets/images/blog-1.jpg"
                  width="100%"
                  alt="about-img"
                />
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="section-title t-left">
                <div class="section-sub-title icon">
                  <h5>About Company</h5>
                </div>
                <div class="section-main-title">
                  <h1>A Little About Us</h1>
                </div>
              </div>
              <div class="about-section-description">
                <p>
                  We've been providing telecoms solutions to businesses for over
                  20 years. We became a network operator in 2012 meaning we can
                  give our customers the absolute best prices.
                  <br />
                  <br />
                  Over the years we've built a loyal customer base, supplying
                  telephone numbers to over 25,000 businesses across the UK. We
                  believe in simple, approachable and cost effective solutions
                  for businesses of all sizes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--about-us-area-end--> */}

      {/* <!--network-area-start--> */}
      <div class="network-area about-net-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="section-title t-left">
                <div class="section-sub-title icon">
                  <h5>Business</h5>
                </div>
                <div class="section-main-title">
                  <h1>Passionate About Your Business</h1>
                </div>
              </div>
              <div class="section-description">
                <p>
                  Over 20 years in telecoms has given us an incredible insight
                  into what businesses need to connect with their customers.
                  We're constantly connecting with ours and investing in new and
                  exciting features to help them grow.
                  <br />
                  <br />
                  We've proudly maintained a 5 star rating from our customers on
                  TrustPilot. If you want to know what we're really about, go
                  check out what they're saying about us.
                </p>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="network-thumb">
                <img src="assets/images/network.png" alt="network-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--network-area-end--> */}
    </div>
  );
};

export default HeroSection;
