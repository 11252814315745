import React from "react";
import Profile from "../../component/ControlPanel/Profile";

const ProfilePage = () => {
  return (
    <div>
      <Profile />
    </div>
  );
};

export default ProfilePage;
