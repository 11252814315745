import React from 'react'
import FrontNavbar from '../essentials/FrontNavbar'
import FrontFooter from '../essentials/FrontFooter'
import HeroSection from './HeroSection'

const ForgetPassword = () => {
  return (
    <div>
        <FrontNavbar/>
        <HeroSection/>
        <FrontFooter/>
    </div>
  )
}

export default ForgetPassword