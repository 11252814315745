import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Form, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";

// Stripe publishable key
const stripePromise = loadStripe(
  "pk_live_51NIuvRELKdOM3HNefMdJ7x2SG1fQtz7NXJElWU9dHFaulGE9Smr4cDNH4r1JOF1hd0xllUOtZJRBmvEN9M0x5QjF00RKHdARuz"
);

const CallWhisper = () => {
  const [show, setShow] = useState(false);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState("");
  const [loadingNumbers, setLoadingNumbers] = useState(false);
  const [loadingPurchase, setLoadingPurchase] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isTrialOver, setIsTrialOver] = useState(false); // State for trial status
  const user_id = localStorage.getItem("user_id");

  // Function to fetch phone numbers from the API
  const fetchNumbers = async () => {
    setLoadingNumbers(true);
    setError("");
    try {
      const response = await axios.get(
        `https://back.faithlandline.co.uk/api/numbers/user/${user_id}`
      );
      const numbers = response.data.map((item) => item.number);
      setPhoneNumbers(numbers);
    } catch (error) {
      console.error("Error fetching numbers", error);
      setError("Failed to load phone numbers. Please try again.");
    } finally {
      setLoadingNumbers(false);
    }
  };

  // Function to check if the trial period is over
  const checkTrialStatus = async () => {
    try {
      const response = await axios.get(
        `https://back.faithlandline.co.uk/api/trial/status/${user_id}`
      );
      setIsTrialOver(response.data.isTrialOver); // Update the trial status based on the response
      return response.data.isTrialOver; // Ensure trial status is returned
    } catch (error) {
      console.error("Error checking trial status", error);
      setError("Failed to check trial status. Please try again.");
    }
  };

  // Fetch phone numbers and trial status on component mount
  useEffect(() => {
    fetchNumbers();
    checkTrialStatus();
  }, []);

  // Function to handle purchase or free trial based on trial status
  const handlePurchase = async () => {
    if (!selectedNumber) {
      setError("Please select a phone number to purchase Call Whisper.");
      return;
    }

    setLoadingPurchase(true);
    setError("");
    setSuccess("");

    try {
      const isTrialOver = await checkTrialStatus(); // Ensure proper trial check
      console.log(isTrialOver);

      if (!isTrialOver) {
        // If trial is over, call Stripe API for paid purchase
        const { data } = await axios.post(
          "https://back.faithlandline.co.uk/api/create-stripe-session",
          {
            productName: "Call Whisper",
            unitAmount: 348, // Specify the amount in cents (£3.48)
            userId: user_id,
            number: selectedNumber,
          }
        );

        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
          sessionId: data.sessionId,
        });

        if (error) {
          console.error("Error during checkout:", error.message);
          toast.error("Payment failed. Please try again.");
        } else {
          setSuccess("Call Whisper purchased successfully via Stripe!");
        }
      } else {
        // If trial is not over, give a free trial
        const trialResponse = await axios.post(
          "https://back.faithlandline.co.uk/api/callwhisper/purchase", // Existing trial API endpoint
          {
            user_id: user_id,
            number: selectedNumber,
          }
        );
        toast.success(trialResponse.data.message);
        setSuccess("Free trial for Call Whisper activated!");
      }

      setSelectedNumber("");
      setShow(false);
    } catch (error) {
      toast.error("Error purchasing Call Whisper");
      console.error("Error purchasing Call Whisper", error);
      setError("Failed to complete the purchase. Please try again.");
    } finally {
      setLoadingPurchase(false);
    }
  };

  return (
    <><Sidebar />
      
      <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
      <Navbar />
      
      <div className="container-fluid py-4">
        <Row>
          <Col md={6}>
            <h3>Call Whisper</h3>
            <p className="text-danger">
              We're so sure you'll love Call Whisper, the first month is now
              free!
            </p>
            <p>
              Call Whisper lets you know if you're receiving a business call
              before you answer. This is ideal if your mobile phone is used to
              receive both personal and business calls.
            </p>
            <p>
              When you answer the call, the caller will continue to hear ringing
              and a message will be played to you, telling you it's a business
              call. You can also enable "press 1 to accept".
            </p>
            <p>
              Call Whisper costs just £3.48 per month and can be added to any of
              your Tamar virtual telephone numbers.
            </p>
            <Button onClick={() => setShow(true)}>Purchase Call Whisper</Button>

            {success && (
              <Alert variant="success" className="mt-3">
                {success}
              </Alert>
            )}
            {error && (
              <Alert variant="danger" className="mt-3">
                {error}
              </Alert>
            )}
          </Col>

          <Col md={6}>
            <img
              src="https://www.tamartelecommunications.co.uk/phonedivert/images/details.png"
              alt="Call Whisper"
              className="img-fluid"
            />
          </Col>
        </Row>

        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Purchase Call Whisper</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form>
              <Form.Group controlId="phoneNumber">
                <Form.Label>Select Phone Number</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedNumber}
                  onChange={(e) => setSelectedNumber(e.target.value)}
                  disabled={loadingNumbers}
                >
                  <option value="">Choose a number...</option>
                  {phoneNumbers.map((number, index) => (
                    <option key={index} value={number}>
                      {number}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={handlePurchase}
              disabled={loadingPurchase}
            >
              {loadingPurchase ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Proceed"
              )}
            </Button>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      </main>
    </>
  );
};

export default CallWhisper;
