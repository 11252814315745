import React from "react";
import MainPage from "../component/MainPage";

const FirstPage = () => {
  return (
    <div>
      <MainPage />
    </div>
  );
};

export default FirstPage;
