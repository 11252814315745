import React from "react";
import PlanCard from "./PlanCard"; // Import the PlanCard component

const ChoosePriceSection = ({
  plans,
  tariffPrice,
  fetchPartialNumbers,
  TariffHandler,
  setButtonDisabled,
  ButtonDisabled,
  setTailor,
}) => {
  return (
    <section id="choosePrice" className="main-page__numbers py-5">
      <div className="container">
        <h2 className="text-center text-black bg-gradient-to-t from-pink-600 to-red-500 mb-5">
          Step 2: Select Your Tariff
        </h2>
        <p className="text-center text-muted mb-4">
          Choose A Tariff With The Amount Of Minutes You Need Each Month Per User
        </p>
        <div className="main-page__numbers-options row justify-content-around">
          {plans.length > 0 ? (
            plans.map((plan, index) => (
              <div
                key={index}
                className="col-md-4 d-flex align-items-stretch"
              >
                <PlanCard
                  plan={plan}
                  index={index}
                  tariffPrice={tariffPrice}
                  fetchPartialNumbers={fetchPartialNumbers}
                  TariffHandler={TariffHandler}
                  setButtonDisabled={setButtonDisabled}
                  ButtonDisabled={ButtonDisabled}
                  setTailor={setTailor}
                />
              </div>
            ))
          ) : (
            <p>Loading tariffs...</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default ChoosePriceSection;
