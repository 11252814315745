import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import Cookies from "js-cookie";

const HeroSection = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match", { position: "bottom-right" });
      return;
    }

    try {
      const response = await axios.post(
        "https://back.faithlandline.co.uk/api/auth/register",
        {
          username: formData.username,
          email: formData.email,
          password: formData.password,
        }
      );

      toast.success(response.data.message, { position: "bottom-right" });

      // Save user ID in cookies
      Cookies.set("user_id", response.data.user.id, { expires: 7 });
      localStorage.setItem("user_id", response.data.user.id); // Expires in 1 day
      localStorage.setItem("token", response.data.token); // Expires in 1 day
    } catch (error) {
      toast.error(error.response?.data?.message || "Error registering user", {
        position: "bottom-right",
      });
    }
  };

  return (
    <div>
      <div className="breatcome-area align-items-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="breatcome-content">
                <div className="breatcome-title">
                  <h1>Register</h1>
                </div>
                <div className="breatcome-text">
                  <ul>
                    <li>
                      <Link to="/">
                        Home <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <span>Register</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-area" style={{ marginTop: "10rem" }}>
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="contact-section">
                <div className="row">
                  <div className="col-lg-12 col-md-12">
                    <div className="contact-title">
                      <h2>Register</h2>
                    </div>
                    <div className="control-contact-form">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 col-md-6 mb-4">
                            <div className="input-box">
                              <label htmlFor="">Username</label>
                              <input
                                type="text"
                                name="username"
                                placeholder="Username"
                                value={formData.username}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 mb-4">
                            <div className="input-box">
                              <label htmlFor="">E-mail</label>
                              <input
                                type="email"
                                name="email"
                                placeholder="E-mail"
                                value={formData.email}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 mb-4">
                            <div className="input-box">
                              <label htmlFor="">Password</label>
                              <input
                                type="password"
                                name="password"
                                placeholder="Password"
                                value={formData.password}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 mb-4">
                            <div className="input-box">
                              <label htmlFor="">Confirm Password</label>
                              <input
                                type="password"
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                value={formData.confirmPassword}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12">
                            <div className="input-button">
                              <button type="submit">Register</button>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <p>
                              Already Have An Account?{" "}
                              <Link to="/login">Login</Link>
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
