import React, { createContext, useState, useEffect } from "react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("user_id") || null);

  const login = (loginData) => {
    const userData = {
      ...loginData.user,
      token: loginData.token,
    };
    setUser(userData.token);
    localStorage.setItem("user_id", JSON.stringify(userData));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user_id");
  };

  useEffect(() => {
    const storedUser = localStorage.getItem("user_id");
    if (storedUser) {
      try {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser.token);
        console.log("AuthProvider user:", user);
      } catch (error) {
        console.error("Error parsing stored user:", error);
      }
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated: !!user, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
